const WALLET = 'WALLET';
const EDIT = 'EDIT';
const VIEW = 'VIEW';
const DELETE = 'DELETE';
const HOME = 'HOME';
const NOTIFICATION_BELL = 'NOTIFICATION_BELL';
const MINT_INVOICE = 'MINT_INVOICE';
/**
 * Export all svg icon for app.
 * @constant {Object} ACTION_TYPES .
 */
export const ICONS = {
  WALLET,
  EDIT,
  VIEW,
  DELETE,
  NOTIFICATION_BELL,
  HOME,
  MINT_INVOICE,
};
