import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';
import { mintInvoice } from '../../../../../Apis/InvoiceApi';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { COMMON } from '../../../../../Configs/Common';
import { CustomButton } from '../../../../Atoms/Buttons/CustomButton';
import { CustomInput } from '../../../../Atoms/Input/CustomInput';
import { MESSAGES } from '../../../../../Configs/Messages';
import { styles } from './Styles';
import { CustomSelect } from '../../../../Atoms/Select/CustomSelect';
import { ENV_CONSTANTS } from '../../../../../Configs/Constants';

/**
 * Component for Invoice Form .
 * @returns
 */
export const InvoiceForm = () => {
  const initialValidation = {
    invoice_amount: false,
    tenure: false,
    supplier_location: false,
    buyer_location: false,
    invoice_number: false,
    invoice_date: false,
    funds_advanced_date: false,
    invoice_limit: false,
    factoring_fee: false,
    discounting_fee: false,
    late_amount: false,
    bank_charges: false,
    additional_fee: false,
    grace_period: false,
    total_fee: false,
    due_date: false,
    payment_date: false,
    seller_received_amount: false,
    buyer_received_amount: false,
    financing_period: false,
    reserve_release_amount: false,
    insured_by: false,
    industry: false,
    advanced_fee: false,
    advanced_amount: false,
    nft_id: false,
  };
  const initialInvoiceData = {
    invoice_status: ENV_CONSTANTS.INVOICE_STATUS_OPTION.FINANCED.value,
    pool_id: ENV_CONSTANTS.POOL_ID_OPTION.P1.value,
  };
  const { state: appDataState } = useContext(AppDataContext);
  const [invoiceData, setInvoiceData] = useState(initialInvoiceData);
  const [formValidation, setFormValidation] = useState(initialValidation);
  const [loading, setLoading] = useState(false);

  const handleChangeForm = (name, value) => {
    setInvoiceData({
      ...invoiceData,
      [name]: value,
    });
  };

  /**
   * Function to call Mint invoice api
   * @param {object} data
   * @returns
   */
  const submitMintInvoice = async () => {
    setLoading(true);
    const response = await mintInvoice(invoiceData);
    if (!response.error) {
      setLoading(false);
      toast.success(MESSAGES.MINT_SUCCESS);
      return true;
    }
    if (response.error) {
      setLoading(false);
      toast.error(response.message);
    }
    return false;
  };

  return (
    <>
      <h6 style={styles.titleText(appDataState.appData.theme)}>
        {COMMON.PAGE_TITLE.MINT_INVOICE}
      </h6>
      <Row>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            input={invoiceData?.invoice_amount}
            inputHandler={handleChangeForm}
            placeholder="Invoice Amount"
            min={0}
            title="Invoice Amount"
            name="invoice_amount"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            input={invoiceData?.tenure}
            inputHandler={handleChangeForm}
            placeholder="Tenure"
            title="Tenure"
            min={0}
            name="tenure"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <CustomInput
            required
            input={invoiceData?.supplier_location}
            inputHandler={handleChangeForm}
            placeholder="Supplier Location"
            title="Supplier Location"
            name="supplier_location"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
        <Col lg={6}>
          <CustomInput
            required
            input={invoiceData?.buyer_location}
            inputHandler={handleChangeForm}
            placeholder="Buyer Location"
            title="Buyer Location"
            name="buyer_location"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <CustomInput
            required
            input={invoiceData?.invoice_number}
            inputHandler={handleChangeForm}
            placeholder="Invoice Number"
            title="Invoice Number"
            name="invoice_number"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
        <Col lg={6}>
          <CustomSelect
            inputHandler={handleChangeForm}
            name="pool_id"
            title="Pool Id"
            value={invoiceData?.pool_id}
            options={ENV_CONSTANTS.POOL_ID_OPTION}
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <CustomInput
            required
            type="date"
            input={invoiceData?.invoice_date}
            inputHandler={handleChangeForm}
            placeholder="Invoice Date"
            title="Invoice Date"
            name="invoice_date"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
        <Col lg={6}>
          <CustomInput
            required
            type="date"
            input={invoiceData?.funds_advanced_date}
            inputHandler={handleChangeForm}
            placeholder="Funds Advanced Date"
            title="Funds Advanced Date"
            name="funds_advanced_date"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.invoice_limit}
            inputHandler={handleChangeForm}
            placeholder="Invoice Limit"
            title="Invoice Limit"
            name="invoice_limit"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.nft_id}
            inputHandler={handleChangeForm}
            placeholder="Nft Id"
            title="Nft Id"
            name="nft_id"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.factoring_amount}
            inputHandler={handleChangeForm}
            placeholder="Factoring Fee"
            title="Factoring Fee"
            name="factoring_amount"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.factoring_fee}
            inputHandler={handleChangeForm}
            placeholder="Factoring Fee Percentage"
            title="Factoring Fee Percentage"
            name="factoring_fee"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.discounting_amount}
            inputHandler={handleChangeForm}
            placeholder="Discounting Fee"
            title="Discounting Fee"
            name="discounting_amount"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.discounting_fee}
            inputHandler={handleChangeForm}
            placeholder="Discounting Fee Percentage"
            title="Discounting Fee Percentage"
            name="discounting_fee"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.late_fee}
            inputHandler={handleChangeForm}
            placeholder="Late Fee Percentage"
            title="Late Fee Percentage"
            name="late_fee"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.late_amount}
            inputHandler={handleChangeForm}
            placeholder="Late Fee"
            title="Late Fee"
            name="late_amount"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.bank_charges}
            inputHandler={handleChangeForm}
            placeholder="Bank Charges"
            title="Bank Charges"
            name="bank_charges"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.additional_fee}
            inputHandler={handleChangeForm}
            placeholder="Additional Fee"
            title="Additional Fee"
            name="additional_fee"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <CustomInput
            required
            input={invoiceData?.grace_period}
            inputHandler={handleChangeForm}
            placeholder="Grace Period"
            title="Grace Period"
            name="grace_period"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.total_fee}
            inputHandler={handleChangeForm}
            placeholder="Total Fee"
            title="Total Fee"
            name="total_fee"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <CustomInput
            required
            type="date"
            input={invoiceData?.due_date}
            inputHandler={handleChangeForm}
            placeholder="Due Date"
            title="Due Date"
            name="due_date"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
        <Col lg={6}>
          <CustomInput
            required
            type="date"
            input={invoiceData?.payment_date}
            inputHandler={handleChangeForm}
            placeholder="Payment Date"
            title="Payment Date"
            name="payment_date"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.buyer_received_amount}
            inputHandler={handleChangeForm}
            placeholder="Buyer Received Amount"
            title="Buyer Received Amount"
            name="buyer_received_amount"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.seller_received_amount}
            inputHandler={handleChangeForm}
            placeholder="Seller Received Amount"
            title="Seller Received Amount"
            name="seller_received_amount"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.financing_period}
            inputHandler={handleChangeForm}
            placeholder="Financing Period"
            title="Financing Period"
            name="financing_period"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.reserve_release_amount}
            inputHandler={handleChangeForm}
            placeholder="Reserve Release Amount"
            title="Reserve Release Amount"
            name="reserve_release_amount"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <CustomInput
            required
            input={invoiceData?.insured_by}
            inputHandler={handleChangeForm}
            placeholder="Insured By"
            title="Insured By"
            name="insured_by"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
        <Col lg={6}>
          <CustomInput
            required
            input={invoiceData?.industry}
            inputHandler={handleChangeForm}
            placeholder="Industry"
            title="Industry"
            name="industry"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <CustomSelect
            inputHandler={handleChangeForm}
            name="invoice_status"
            title="Invoice Status"
            value={invoiceData?.invoice_status}
            options={ENV_CONSTANTS.INVOICE_STATUS_OPTION}
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
          />
        </Col>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.advanced_fee}
            inputHandler={handleChangeForm}
            placeholder="Advanced Ratio"
            title="Advanced Ratio"
            name="advanced_fee"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <CustomInput
            required
            type="number"
            min={0}
            input={invoiceData?.advanced_amount}
            inputHandler={handleChangeForm}
            placeholder="Advanced Amount"
            title="Advanced Amount"
            name="advanced_amount"
            rules="required"
            inputStyle={styles.inputStyle(appDataState.appData.theme)}
            isValid={(name, isValid) =>
              setFormValidation({ ...formValidation, [name]: isValid })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mt-3">
          <CustomButton
            type="secondary"
            title="Mint Invoice"
            handler={submitMintInvoice}
            isLoading={loading}
            disabled={!Object.values(formValidation).every(value => value)}
          />
        </Col>
      </Row>
    </>
  );
};
