import { COMMON } from '../../../Configs/Common';

const brandName = COMMON.APP_NAME;

/**
 * Component for Brand Icon will be used in Header.
 *
 * @component
 */
export const BrandIcon = ({ url }) => (
  <img
    src={url}
    width="30"
    height="30"
    className="d-inline-block align-top"
    alt={brandName}
  />
);
