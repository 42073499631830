/**
 * Return styles for Sidebar
 * @returns {CSSProperties}
 * Sidebar styles use the theme to set the proper colors.
 * @param {isDrawerOpen} isDrawerOpen .
 */

function menuItem(isActive) {
  const style = {
    marginTop: 36,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    opacity: isActive ? 1 : 0.4,
  };
  return style;
}

function menuLink() {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'unset',
    color: '#000',
  };
  return style;
}

function nameText() {
  const style = {
    fontSize: 14,
    color: '#000',
  };
  return style;
}
export const styles = {
  menuItem,
  menuLink,
  nameText,
};
