/**
 * Return styles for the Modal Body
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function actionButton() {
  const style = {
    padding: 0,
    backgroundColor: 'transparent',
    border: 'none',
  };
  return style;
}

export const styles = { actionButton };
