const LOGIN_ADMIN = '/auth/login';
const GET_NOTIFICATIONS = '/notifications/list';
const CREATE_NOTIFICATION = '/notifications/create';
const EDIT_NOTIFICATION = '/notifications/update';
const DELETE_NOTIFICATION = '/notifications/delete';

const GET_RELEASE = '/release/list';
const CREATE_RELEASE = '/release/create';
const PUBLISH_RELEASE = '/release/publish';
const EDIT_RELEASE = '/release/update';
const CREATE_KYC = '/kyc/submit';

const GET_STATISTICS = '/statistics/list';
const FILE_UPLOAD = '/file/upload';
const GET_INVOICES = '/invoice/list';
const MINT_INVOICES = '/invoice/create';

export const ROUTE_NAMES = {
  LOGIN_ADMIN,
  GET_NOTIFICATIONS,
  EDIT_NOTIFICATION,
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  CREATE_KYC,
  GET_STATISTICS,
  GET_RELEASE,
  CREATE_RELEASE,
  EDIT_RELEASE,
  PUBLISH_RELEASE,
  FILE_UPLOAD,
  GET_INVOICES,
  MINT_INVOICES,
};
