/**
 * Style for Image preview
 */
function ImagePreview() {
  const style = {
    color: '#fff',
  };
  return style;
}

/**
 * Styles for Login button
 */
function LoginButton() {
  const style = {
    width: '50%',
    border: 'none',
    cursor: 'pointer',
    background: '#2da3b4',
    borderRadius: '4px',
    fontFamily: 'Averta-Semibold',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#ffffff',
  };
  return style;
}
/**
 * Styles for Login input field
 */
function passwordRow() {
  const style = {
    position: 'relative',
  };
  return style;
}

/**
 * Styles for Login input field
 */
function inputLable(theme) {
  const style = {
    color: theme.common.white,
  };
  return style;
}

export const styles = {
  LoginButton,
  ImagePreview,
  passwordRow,
  inputLable,
};
