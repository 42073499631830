import { Dashboard } from './Components/Dashboard/Dashboard';

/**
 * Component to be the first component to show.
 *
 * @component
 */
export const Home = () => {
  return (
    <div className="mb-2 pt-5">
      <Dashboard />
    </div>
  );
};
