/**
 * Return styles for head
 * @returns {CSSProperties}
 * head styles sets the padding and color.
 */
function head() {
  const style = {
    textDecoration: 'none',
    color: '#000000',
    padding: '0 5px',
    marginBottom: '0 px',
  };
  return style;
}

/**
 * Return styles for Paragraph in the Header
 * @returns {CSSProperties}
 */
const paragraph = () => {
  const style = { margin: 0 };
  return style;
};

/**
 * Return styles for Paragraph in the Header
 * @returns {CSSProperties}
 */
const headerTitle = () => {
  const style = { width: 'max-content', fontSize: 38, fontWeight: '600' };
  return style;
};

/**
 * Return styles for Header Title
 * @returns {CSSProperties}
 */
const titleRow = () => {
  const style = { width: 'max-content' };
  return style;
};

/**
 * Return styles for Header toggle button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
const toggleButton = theme => {
  const style = {
    marginRight: 8,
    marginTop: 2,
    height: 42,
    backgroundColor: 'transparent',
    width: 'max-content',
    color: theme.primary.contrastText,
    border: 'none',
  };
  return style;
};

/**
 * Return styles for Header logout Button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
const logoutButton = theme => {
  const style = {
    marginRight: 8,
    marginTop: 2,
    height: 42,
    backgroundColor: 'transparent',
    width: 'max-content',
    color: theme.primary.contrastText,
    border: 'none',
  };
  return style;
};

/**
 * Return styles for Header main container
 * @returns {CSSProperties}
 */
const headerMain = () => {
  const style = { padding: '32px 22px 0px' };
  return style;
};

/**
 * Return styles for Header action view
 * @returns {CSSProperties}
 */
const headerContaint = () => {
  const style = { width: 'max-content', display: 'flex', alignItems: 'center' };
  return style;
};

export const styles = {
  head,
  paragraph,
  headerTitle,
  headerContaint,
  headerMain,
  toggleButton,
  titleRow,
  logoutButton,
};
