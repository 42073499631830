export const ViewIcon = ({ height, width, fillColor, className }) => (
  <svg
    width={height}
    height={width}
    className={className}
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24.000000 24.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
      fill={fillColor}
      stroke="none"
    >
      <path
        d="M44 207 c-3 -8 -4 -52 -2 -98 l3 -84 39 -3 c25 -2 36 0 31 7 -3 6
-17 11 -31 11 -23 0 -24 2 -24 80 l0 80 35 0 c31 0 35 -3 35 -25 0 -20 5 -25
25 -25 14 0 25 -4 25 -10 0 -5 5 -10 10 -10 20 0 9 37 -18 63 -22 22 -38 27
-75 27 -31 0 -49 -5 -53 -13z"
        fill={fillColor}
      />
      <path
        fill={fillColor}
        d="M146 94 c-32 -32 -6 -88 38 -82 11 1 26 -1 33 -5 6 -4 14 -3 17 2 4
5 2 12 -2 15 -5 3 -7 16 -5 29 9 46 -48 75 -81 41z m59 -34 c0 -18 -6 -26 -23
-28 -24 -4 -38 18 -28 44 3 9 15 14 28 12 17 -2 23 -10 23 -28z"
      />
    </g>
  </svg>
);
