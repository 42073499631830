import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  deleteNotification,
  getNotifications,
} from '../../../Apis/NotificationService';

import { ENV_CONSTANTS } from '../../../Configs/Constants';
import { MESSAGES } from '../../../Configs/Messages';
import { HELPERS } from '../../../Helpers';
import { CustomButton } from '../../Atoms/Buttons/CustomButton';
import { NotificationForm } from './Components/NotificationForm/NotificationForm';
import { CustomModal } from '../../Modals/CustomModal/CustomModal';
import { NotificationTable } from './Components/NotificationTable/NotificationTable';
import { styles } from './Styles';

/**
 * Component to be the first component to show.
 *
 * @component
 */
export const Notifications = () => {
  const [notificationModalShow, setNotificationModalShow] = useState(false);
  const [activeNotificationData, setActiveNotificationData] = useState();
  const [notificationFormMode, setNotificationFormMode] = useState();
  const [notificationList, setNotificationList] = useState([]);
  useEffect(() => {
    getNotificationData();
  }, []);

  const getNotificationData = () => {
    getNotifications().then(response => {
      if (HELPERS.isArray(response?.data?.docs)) {
        setNotificationList(response?.data?.docs);
      }
    });
  };

  const closeNotificationModal = props => {
    if (props.withSuccess) {
      getNotificationData();
    }
    setActiveNotificationData(null);
    setNotificationModalShow(false);
  };

  const onEditNotification = row => {
    setNotificationFormMode(ENV_CONSTANTS.FORM_OPEN_MODE.EDIT);
    setActiveNotificationData(row.rowData);
    setNotificationModalShow(true);
  };

  const onViewNotification = row => {
    setNotificationFormMode(ENV_CONSTANTS.FORM_OPEN_MODE.VIEW);
    setActiveNotificationData(row.rowData);
    setNotificationModalShow(true);
  };

  const onCreateNotification = () => {
    setNotificationFormMode(ENV_CONSTANTS.FORM_OPEN_MODE.CREATE);
    setNotificationModalShow(true);
  };

  const onDeleteNotification = async row => {
    const response = await deleteNotification(row.rowData);
    if (!response.error) {
      toast.success(MESSAGES.DELETE_NOTIFICATION_SUCCESS);
      getNotificationData();
      return true;
    }
    toast.error(response.message.message);
    return false;
  };

  return (
    <div className="mb-2 p-5">
      <div className="d-flex justify-content-end">
        <div style={styles.createButtonView()}>
          <CustomButton
            type="secondary"
            title="Create New Notification"
            handler={onCreateNotification}
          />
        </div>
      </div>
      <NotificationTable
        onView={onViewNotification}
        onEdit={onEditNotification}
        notificationList={notificationList}
        onDelete={onDeleteNotification}
      />
      <CustomModal
        display={notificationModalShow}
        handleClose={closeNotificationModal}
      >
        <NotificationForm
          mode={notificationFormMode}
          data={activeNotificationData}
        />
      </CustomModal>
    </div>
  );
};
