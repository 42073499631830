import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Image, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Preview from '../../../Assets/Preview.png';
import { styles } from './Styles';
import { login } from '../../../Actions/Auth';
import logger from '../../../Helpers/Logger';
import { HELPERS } from '../../../Helpers';
import { MESSAGES } from '../../../Configs/Messages';
import { CustomInput } from '../../Atoms/Input/CustomInput';
import { AppDataContext } from '../../../Contexts/AppData';

/**
 * Login  Form  Component for the application.
 *
 * @component LoginForm
 */

export const LoginForm = () => {
  const initialValidation = {
    email: false,
    password: false,
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formValidation, setFormValidation] = useState(initialValidation);
  const { state: appDataState } = useContext(AppDataContext);

  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  });
  const { email, password } = inputs;

  const handleChangeForm = (key, value) => {
    setInputs(inputs => ({ ...inputs, [key]: value }));
  };

  const handleSubmit = async () => {
    try {
      if (HELPERS.isNotEmpty(inputs)) {
        const loginPromice = login(inputs, dispatch);
        toast.promise(loginPromice, {
          pending: MESSAGES.LOGING,
          error: MESSAGES.LOGIN_ERROR,
        });
        const loginResult = await loginPromice;
        if (loginResult) {
          navigate('/');
        }
      }
    } catch (error) {
      logger.error(error.message);
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <Form>
      <Row className="justify-content-center">
        <Col xs={6}>
          <Form.Group>
            <Form.Label
              style={styles.inputLable(appDataState.appData.theme)}
              className="d-flex mt-1"
            >
              Email
            </Form.Label>
            <CustomInput
              required
              input={email}
              inputHandler={handleChangeForm}
              placeholder="Enter your email"
              name="email"
              rules="required|email"
              isValid={(name, isValid) =>
                setFormValidation({ ...formValidation, [name]: isValid })
              }
            />
          </Form.Group>

          <Form.Group>
            <Form.Label
              style={styles.inputLable(appDataState.appData.theme)}
              className="mb-3 d-flex mt-2"
            >
              Password
            </Form.Label>
            <CustomInput
              type={passwordShown ? 'text' : 'password'}
              required
              input={password}
              inputHandler={handleChangeForm}
              placeholder="Enter your password"
              rules="required"
              isValid={(name, isValid) =>
                setFormValidation({ ...formValidation, [name]: isValid })
              }
              name="password"
              suffixAction={{
                icon: (
                  <Image
                    style={styles.ImagePreview()}
                    src={Preview}
                    alt="preview"
                  />
                ),
                action() {
                  togglePassword();
                },
              }}
            />
          </Form.Group>

          <div
            className="mb-3 d-flex mt-1"
            style={{ color: 'white', textAlign: 'right' }}
          >
            <Form.Check />
            <div className="mx-4"> Remember Me </div>
          </div>

          <Button
            disabled={!Object.values(formValidation).every(value => value)}
            style={styles.LoginButton()}
            onClick={handleSubmit}
          >
            Sign in
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
