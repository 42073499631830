import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';
import { ENV_CONSTANTS } from '../Configs/Constants';
// All API request call apiHandler (helper function)

/**
 * Function to call Release get api
 * @param {string} address
 * @returns release data
 */
export const getReleaseData = async query => {
  try {
    return await apiHandler(
      REQUEST_METHODS.GET,
      `${ROUTE_NAMES.GET_RELEASE}?is_draft=${query}`,
      {},
    );
  } catch {
    return ENV_CONSTANTS.EMPTY_ARRAY;
  }
};

/**
 * Function to call create release
 * @param {Object} reqBody
 * @returns create release data
 */
export const createRelease = async reqBody => {
  try {
    return await apiHandler(
      REQUEST_METHODS.POST,
      ROUTE_NAMES.CREATE_RELEASE,
      {},
      {},
      reqBody,
    );
  } catch {
    return ENV_CONSTANTS.EMPTY_ARRAY;
  }
};

/**
 * Function to call edit release
 * @param {Object} reqBody
 * @returns edit release data
 */
export const editRelease = async reqBody => {
  try {
    return await apiHandler(
      REQUEST_METHODS.POST,
      `${ROUTE_NAMES.EDIT_RELEASE}/${reqBody._id}`,
      {},
      {},
      reqBody,
    );
  } catch {
    return ENV_CONSTANTS.EMPTY_ARRAY;
  }
};
/**
 * Function to call publish release
 * @param {string} _id
 * @returns publish release data
 */
export const publishRelease = async _id => {
  try {
    return await apiHandler(
      REQUEST_METHODS.POST,
      `${ROUTE_NAMES.PUBLISH_RELEASE}/${_id}`,
      {},
      {},
    );
  } catch {
    return ENV_CONSTANTS.EMPTY_ARRAY;
  }
};
