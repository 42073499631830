import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';

/**
 * @returns all statistics data
 */
export const getStatistics = async date => {
  try {
    let url = `${ROUTE_NAMES.GET_STATISTICS}`;
    if (date) {
      url += `?date=${date}`;
    }
    return await apiHandler(REQUEST_METHODS.GET, url, date, {});
  } catch (error) {
    return error;
  }
};
