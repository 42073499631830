import Dropdown from 'react-bootstrap/Dropdown';

/**
 * Component for Dropdown Button
 * @param {string} buttonName
 * @param {Array} menuItem
 * @returns
 */
export const DropdownButton = ({ buttonName, inputHandler, menuItem = [] }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {buttonName}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {Object.values(menuItem).map(option => {
          return (
            <Dropdown.Item onClick={() => inputHandler(option.name)}>
              {option.name}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
