export const DeleteIcon = ({ height, width, fillColor, className }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={height}
    height={width}
    className={className}
    viewBox="0 0 24.000000 24.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
      fill={fillColor}
      stroke="none"
    >
      <path
        fill={fillColor}
        d="M62 213 c-27 -3 -32 -8 -27 -21 4 -9 11 -48 16 -86 5 -39 13 -74 18
-79 4 -4 30 -7 57 -5 l49 3 8 50 c4 28 12 68 17 90 5 22 8 41 7 41 -6 6 -117
11 -145 7z m113 -65 c-16 -115 -12 -108 -55 -108 -43 0 -39 -7 -55 108 l-5 42
60 0 60 0 -5 -42z"
      />
    </g>
  </svg>
);
