import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';
import { ENV_CONSTANTS } from '../Configs/Constants';
// All API request call apiHandler (helper function)

/**
 * Function to call create kyc
 * @param {Onject} reqBody
 * @returns create kyc data
 */
export const updateKyc = async reqBody => {
  try {
    const url = ROUTE_NAMES.CREATE_KYC;
    return await apiHandler(REQUEST_METHODS.POST, url, {}, {}, reqBody);
  } catch {
    return ENV_CONSTANTS.EMPTY_ARRAY;
  }
};
