import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';
import { ENV_CONSTANTS } from '../Configs/Constants';
// All API request call apiHandler (helper function)

/**
 * Function to call transaction history api
 * @param {string} address
 * @returns transaction history data
 */
export const getNotifications = async () => {
  try {
    const url = ROUTE_NAMES.GET_NOTIFICATIONS;
    return await apiHandler(REQUEST_METHODS.GET, url, {});
  } catch {
    return ENV_CONSTANTS.EMPTY_ARRAY;
  }
};

/**
 * Function to call create notification
 * @param {Onject} reqBody
 * @returns create notification data
 */
export const createNotification = async reqBody => {
  try {
    const url = ROUTE_NAMES.CREATE_NOTIFICATION;
    return await apiHandler(REQUEST_METHODS.POST, url, {}, {}, reqBody);
  } catch {
    return ENV_CONSTANTS.EMPTY_ARRAY;
  }
};

/**
 * Function to call edit notification
 * @param {Onject} reqBody
 * @returns edit notification data
 */
export const editNotification = async reqBody => {
  const notificationId = reqBody._id;
  try {
    const url = `${ROUTE_NAMES.EDIT_NOTIFICATION}/${notificationId}`;
    return await apiHandler(REQUEST_METHODS.POST, url, {}, {}, reqBody);
  } catch {
    return ENV_CONSTANTS.EMPTY_ARRAY;
  }
};

/**
 * Function to call delete notification
 * @param {Onject} reqBody
 */
export const deleteNotification = async reqBody => {
  const notificationId = reqBody._id;
  try {
    const url = `${ROUTE_NAMES.DELETE_NOTIFICATION}/${notificationId}`;
    return await apiHandler(REQUEST_METHODS.POST, url, {}, {});
  } catch {
    return ENV_CONSTANTS.EMPTY_ARRAY;
  }
};
