import { ThemeProvider, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useContext } from 'react';
import { AppDataContext } from '../../Contexts/AppData';
import { Header } from '../Molecules/Header/Header';
import { COMMON } from '../../Configs/Common';
import { RoutesContent } from './RoutesContent';
import { styles } from './Styles';

import 'react-toastify/dist/ReactToastify.css';
import { Sidebar } from '../Molecules/Sidebar/Sidebar';
/**
 * Component wrap the whole application unify the layout.
 *
 * @component
 */
export const MainLayout = () => {
  // Get use of AppDataContext that holds the application needed data
  const { state: appDataState } = useContext(AppDataContext);

  const { auth } = useSelector(state => state);
  const isUserLoggedin = auth.accessToken !== '';

  return (
    <ThemeProvider breakpoints={['xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
      <Helmet title={COMMON.APP_NAME}>
        <link rel="icon" href={COMMON.FAVICON} />
      </Helmet>
      {isUserLoggedin && <Sidebar />}
      <Container fluid="true" style={styles.outerContainer()}>
        <div
          style={styles.mainWrapper(
            appDataState.appData.isDrawerOpen && isUserLoggedin,
          )}
        >
          {isUserLoggedin && <Header />}
          <ToastContainer
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            position="top-right"
            autoClose={COMMON.NOTIFICATION_AUTO_CLOSE_MS}
            theme="light"
            hideProgressBar={false}
            newestOnTop={false}
            rtl={false}
          />
          <div>
            <RoutesContent />
          </div>
        </div>
      </Container>
    </ThemeProvider>
  );
};
