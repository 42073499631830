import { useState, useEffect, useContext, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { CustomTable } from '../../../../Atoms/CustomTable/CustomTable';
import { getStatistics } from '../../../../../Apis/StatisticsService';
import { HELPERS } from '../../../../../Helpers';
import { ENV_CONSTANTS } from '../../../../../Configs/Constants';
import { styles } from './Styles';

/**
 * Component to show  contract information in Home page.
 */
export const Dashboard = () => {
  const { state: appDataState } = useContext(AppDataContext);
  const [lenderPoolDetails, setLenderPoolDetails] = useState([]);
  const [date, setDate] = useState(new Date());
  const today = new Date();

  const getStatisticsData = useCallback(async () => {
    getStatistics(HELPERS.formatDate(date)).then(response => {
      if (HELPERS.isArray(response?.data)) {
        setLenderPoolDetails(response.data);
      }
    });
  }, [date]);

  useEffect(() => {
    getStatisticsData();
  }, [getStatisticsData]);

  const columns = [
    {
      name: ' Name',
      key: 'key',
    },
    {
      name: ' Address',
      key: 'contract_address',
    },
    {
      name: 'Value',
      key: 'value',
    },
    {
      name: 'Date',
      key: 'updatedAt',
    },
  ];

  const formattedStatistics = lenderPoolDetails.map(item => {
    return {
      key: item.key,
      contract_address: item.contract_address,
      value: `${item.value} ${item.symbol}`,
      updatedAt: HELPERS.formatDate(item.updatedAt),
    };
  });

  return (
    <div className="mb-2 p-2">
      <div className=" mb-2 d-flex justify-content-end">
        <div className="d-flex flex-row">
          <h5 className="p-2 text-black">Select Date</h5>
          <div>
            <Form.Control
              type="date"
              name="date picker"
              placeholder="DateRange"
              value={date}
              onChange={e => setDate(e.target.value)}
              max={HELPERS.formatDate(today)}
            />
          </div>
        </div>
      </div>

      {HELPERS.formatDate(date) ? (
        <>
          <CustomTable columns={columns} data={formattedStatistics} />
          <CSVLink
            style={styles.csvButton(appDataState.appData.theme)}
            data={formattedStatistics}
            filename={ENV_CONSTANTS.CSV_DATA.FILENAME}
            headers={columns}
            className="table-csv-button"
          >
            {ENV_CONSTANTS.CSV_DATA.BUTTON_TEXT}
          </CSVLink>
        </>
      ) : (
        <h4>No Data available ,please select another date</h4>
      )}
    </div>
  );
};
