import { toast } from 'react-toastify';
import { updateKyc } from '../../../../../../Apis/KycService';
import { MESSAGES } from '../../../../../../Configs/Messages';

export const updateKycAction = async data => {
  const response = await updateKyc(data);
  if (!response.error) {
    toast.success(MESSAGES.CREATE_KYC_SUCCESS);
    return true;
  }
  if (response.isError) {
    toast.error(MESSAGES.SOME_ERROR);
    return false;
  }
  if (response?.data?.errors) {
    response.data.errors.forEach(error => {
      toast.error(error.msg);
    });
  }

  return false;
};
