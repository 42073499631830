export const WalletIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.25 9.08028H23.8337C23.8337 5.39998 21.6285 3.25 17.8922 3.25H8.10847C4.37218 3.25 2.16699 5.39998 2.16699 9.03334V16.9667C2.16699 20.6 4.37218 22.75 8.10847 22.75H17.8922C21.6285 22.75 23.8337 20.6 23.8337 16.9667V16.6287H19.25C17.1226 16.6287 15.3981 14.9473 15.3981 12.8733C15.3981 10.7992 17.1226 9.11784 19.25 9.11784V9.08028ZM19.25 10.6951H23.0248C23.4715 10.6951 23.8337 11.0482 23.8337 11.4838V14.2252C23.8285 14.6586 23.4693 15.0088 23.0248 15.0138H19.3366C18.2597 15.028 17.3179 14.3091 17.0737 13.2864C16.9513 12.6515 17.1231 11.9964 17.5428 11.4966C17.9626 10.9968 18.5874 10.7034 19.25 10.6951ZM19.4137 13.5774H19.77C20.2273 13.5774 20.5981 13.2159 20.5981 12.77C20.5981 12.3241 20.2273 11.9626 19.77 11.9626H19.4137C19.1949 11.9601 18.9842 12.043 18.8286 12.193C18.6731 12.3429 18.5855 12.5473 18.5855 12.7606C18.5855 13.2081 18.9547 13.5723 19.4137 13.5774ZM7.29958 9.08028H13.4144C13.8718 9.08028 14.2425 8.71879 14.2425 8.27287C14.2425 7.82695 13.8718 7.46546 13.4144 7.46546H7.29958C6.84595 7.46543 6.47671 7.82123 6.47144 8.26348C6.47141 8.71095 6.84066 9.07514 7.29958 9.08028Z"
      fill={fillColor}
    />
  </svg>
);
