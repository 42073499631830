// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { URLS } from '../../Configs/FrontendUrls';

export const PrivateRoute = () => {
  // Add your own authentication on the below line.
  const { auth } = useSelector(state => state);
  const isUserLoggedin = auth.accessToken !== '';
  if (isUserLoggedin) {
    return <Outlet />;
  }
  return <Navigate to={URLS.URL.LOGIN} />;
};
