import { ENV_CONSTANTS } from '../../../Configs/Constants';

/**
 * Return styles for Sidebar
 *  @param {isDrawerOpen} isDrawerOpen .
 * @returns {CSSProperties}
 */
function sidebarMain(isDrawerOpen) {
  const style = {
    width: ENV_CONSTANTS.DRAWER_WIDTH,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'Column',
    justifyContent: 'space-between',
    marginLeft: isDrawerOpen ? 0 : -ENV_CONSTANTS.DRAWER_WIDTH,
    position: 'fixed',
    height: '100vh',
    overflow: 'auto',
    transition: 'all 0.5s ease-in-out',
  };
  return style;
}

function brandView() {
  const style = {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };
  return style;
}

function bottomNavigation() {
  const style = {
    width: '100%',
  };
  return style;
}

export const styles = {
  brandView,
  sidebarMain,
  bottomNavigation,
};
