import favicon from '../Assets/favicon.ico';

const LIGHT_THEME = {
  id: 1,
  name: 'Light Theme',
  common: {
    white: '#ffffff',
    black: '#000000',
    gray: '#D3D3D3',
    gradient: ['#EDDDEB', '#A2A5F4', '#98B8EF'],
  },
  primary: {
    main: '#F4F8FB',
    backgroundColor: '#ffffff',
    contrastText: '#4db5c4',
    textColor: '#0A0B20',
    errorColor: 'red',
    gray: '#99A3AC',
  },
  secondary: {
    main: '#768CFA',
    backgroundColor: 'grey',
    contrastText: '#ffffff',
    textColor: '#ffffff',
    errorColor: 'red',
  },
  header: 'light',
  logoUrl: favicon,
  faviconUrl: 'https://polytrade.app/poly_favicon_trans.svg',
};

const DARK_THEME = {
  id: 2,
  name: 'Dark Theme',
  primary: {
    main: '#212022',
    backgroundColor: '#262626',
    contrastText: '#ffffff',
    textColor: '#ffffff',
    errorColor: 'red',
    gray: '#99A3AC',
  },
  common: {
    white: '#ffffff',
    black: '#000000',
    gray: '#D3D3D3',
    gradient: ['#EDDDEB', '##A2A5F4', '#98B8EF'],
  },
  secondary: {
    main: '#768CFA',
    backgroundColor: 'white',
    contrastText: '#4db5c4',
    textColor: '#4db5c4',
    errorColor: 'red',
  },
  header: 'dark',
  logoUrl: favicon,
  faviconUrl: 'https://polytrade.app/poly_favicon_trans.svg',
};

const HEADER_TITLE = 'Dashboard';

export const APP_DATA = { LIGHT_THEME, HEADER_TITLE, DARK_THEME };
