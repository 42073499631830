import { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { COMMON } from '../../../Configs/Common';
import { URLS } from '../../../Configs/FrontendUrls';
import { AppDataContext } from '../../../Contexts/AppData';
import { BrandIcon } from '../../Atoms/Icons/BrandIcon';
import { MenuItem } from '../../Atoms/MenuItem/MenuItem';
import { styles } from './Styles';

/**
 * Component will be the main Side Bar for the application.
 *
 * @component
 */
export const Sidebar = () => {
  const { state: appDataState } = useContext(AppDataContext);
  const upperNavigationsItems = URLS.NAV_LINKS.filter(
    navigation => !navigation.inBottom,
  );
  const bottomNavigationsItem = URLS.NAV_LINKS.filter(
    navigation => navigation.inBottom,
  );
  return (
    <Container
      style={styles.sidebarMain(appDataState.appData.isDrawerOpen)}
      fluid="true"
    >
      <div style={styles.brandView()} className="mt-4">
        <BrandIcon url={appDataState.appData.theme.logoUrl} />
        <span>{COMMON.APP_NAME}</span>
        <div className="mt-4">
          {upperNavigationsItems.map(element => (
            <MenuItem key={element.id} element={element} />
          ))}
        </div>
      </div>
      <div style={styles.bottomNavigation()}>
        {bottomNavigationsItem.map(element => (
          <MenuItem key={element.id} element={element} />
        ))}
      </div>
    </Container>
  );
};
