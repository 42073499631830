import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { setValidationStore } from '../../../Helpers/Validator';
import styles from './Styles';

/**
 * Component to use as an Atom in the application.
 * This component create a Text Input with many options.
 *
 * @component
 * @param {string} input
 * @param {Function} inputHandler
 * @param {boolean} required
 * @param {boolean} disabled
 * @param {any} featuredValue
 * @param {any} suffixAction
 * @param {string} title
 * @param {string} placeholder
 * @param {string} rules
 * @param {boolean} isValid
 * @param {boolean} type
 */
export const CustomSelect = ({
  value,
  inputHandler,
  required,
  disabled,
  title,
  inputStyle,
  name,
  options = [],
}) => {
  const store = useSelector(state => state);

  const handleInput = event => {
    inputHandler(event.target.name, event.target.value);
  };

  useEffect(() => {
    setValidationStore(store);
  }, [store]);

  return (
    <Form.Group style={styles.container()}>
      {title && <Form.Label style={styles.title()}>{title}</Form.Label>}
      <Form.Select
        required={required}
        disabled={disabled}
        value={value}
        name={name}
        style={{ ...styles.input(), ...inputStyle }}
        onChange={handleInput}
      >
        {Object.values(options).map(option => {
          return (
            <option key={option.id} value={option.value}>
              {option.name}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
};
