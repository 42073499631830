import { ACTION_TYPES } from '../Configs/ActionTypes';
import { APP_DATA } from '../Configs/AppData';

/**
 * Void
 * Toggle theme function dispatches an action to be caught in the reducer
 * After a while, these data will comes from remote API (Backend server)
 * @param {number} currentThemeId
 * @param {Dispatch} dispatch
 */
export function toggleTheme(currentThemeId, dispatch) {
  try {
    // Theme type defined to reflect the whole application needed theme
    let theme;

    // Choose the right theme to apply
    switch (currentThemeId) {
      case 1:
        theme = APP_DATA.DARK_THEME;
        break;
      case 2:
      default:
        theme = APP_DATA.LIGHT_THEME;
        break;
    }

    // Dispatched action to the app
    const action = {
      type: ACTION_TYPES.SET_THEME,
      payload: theme,
    };

    dispatch(action);
  } catch {
    //
  }
}

/**
 * Dispatched action to the app.
 * @param {dispatch} dispatch .
 * Current Status of drawer
 * @param {isDrawerOpen} isDrawerOpen .
 */
export function toggleSidebar(dispatch, isDrawerOpen) {
  const action = {
    type: ACTION_TYPES.TOGGLE_SIDEBAR,
    payload: isDrawerOpen,
  };

  dispatch(action);
}
