/**
 * Return styles for the createButtonView
 * @returns {CSSProperties}
 */
function createButtonView() {
  const style = { width: 240 };
  return style;
}
/**
 * Return styles for invoiceCard
 * @returns {CSSProperties}
 */
function invoiceCard() {
  const style = {
    backgroundColor: 'black',
    borderRadius: 25,
    width: 470,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 12,
    color: 'white',
  };
  return style;
}
export const styles = { createButtonView, invoiceCard };
