import log from 'loglevel';
import remote from 'loglevel-plugin-remote';

const customJSON = log => ({
  id: process.env.REACT_APP_CLIENT_APPLICATION_ID,
  message: log.message,
  error: {
    level: log.level.label,
    stacktrace: log.stacktrace,
  },
});

remote.apply(log, {
  format: customJSON,
  url: `${process.env.REACT_APP_SERVER_URL}/clientLogger`,
});

log.enableAll();

export default log;
