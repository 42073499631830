/**
 * Return styles for Custom buttons
 * @returns {CSSProperties}
 * CustomButton styles use the type to set the proper color and look
 * @param {string} type .
 */
function customButton(type) {
  let style = {
    width: '100%',
    color: '#000000',
    backgroundColor: '#f4f8fb',
    fontSize: '1em',
    fontWeight: 800,
    padding: '10px',
    border: 'none',
    borderRadius: '40px',
  };
  switch (type) {
    case 'secondary':
      style = { ...style, color: '#ffffff', backgroundColor: '#768cfa' };
      break;
    default:
      break;
  }

  return style;
}

/**
 * Return styles for Custom buttons container
 * @returns {CSSProperties}
 */
function container() {
  const style = { position: 'relative', padding: 0 };
  return style;
}

/**
 * Return styles for Loading look and feels Custom Buttons
 * @returns {CSSProperties}
 */
const isLoading = () => {
  const style = {
    backgroundColor: 'white',
    opacity: 0.5,
    padding: '0.7em',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  };
  return style;
};

/**
 * Return styles for Modal Close Buttons
 * @returns {CSSProperties}
 */
const closeButton = () => {
  const style = {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    padding: 0,
    backgroundColor: '#f3f8fa',
    borderColor: '#ffffff',
    color: '#99a3ac',
    position: 'absolute',
    top: -15,
    right: -15,
    cursor: 'pointer',
    zIndex: 2,
  };
  return style;
};

export const styles = { customButton, closeButton, container, isLoading };
