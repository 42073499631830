import ahrvo from '../Assets/ahrvo.png';
import fractal from '../Assets/fractalLogo.png';
import synaps from '../Assets/synaps.png';

const PRODUCTION_ENV = 'PROD';
const DEVELOPMENT_ENV = 'DEV';
const CURRENT_ENV =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? DEVELOPMENT_ENV
    : PRODUCTION_ENV;

const POLYGON_SCAN =
  CURRENT_ENV === DEVELOPMENT_ENV
    ? 'https://mumbai.polygonscan.com/tx/'
    : 'https://polygonscan.com/tx/';

const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000';

const REDIRECT_URL =
  process.env.REACT_APP_REDIRECT_URL || 'http://localhost:3000';

const APP_NAME = process.env.REACT_APP_APP_NAME || 'Polytrade';

const DRAWER_WIDTH = 180;

const FAVICON =
  process.env.REACT_APP_FAVICON ||
  'https://polytrade.app/poly_favicon_trans.svg';

const DUMMY_USER = {
  profileImage:
    'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80',
  email: 'test@gmail.com',
};

const NOTIFICATION_STATUS = {
  ACTIVE: 'Active',
  IN_ACTIVE: 'InActive',
};

const KYC_STATUS_OPTION = {
  YES: {
    id: 0,
    name: 'Yes',
    value: true,
  },
  NO: {
    id: 1,
    name: 'No',
    value: false,
  },
};

const INVOICE_STATUS_OPTION = {
  FINANCED: {
    id: 0,
    name: 'FINANCED',
    value: 'FINANCED',
  },
  SETTLED: {
    id: 1,
    name: 'SETTLED',
    value: 'SETTLED',
  },
};

const POOL_ID_OPTION = {
  P1: {
    id: 0,
    name: 'P1',
    value: 'P1',
  },
  P2: {
    id: 1,
    name: 'P2',
    value: 'P2',
  },
  P3: {
    id: 1,
    name: 'P3',
    value: 'P3',
  },
  P4: {
    id: 1,
    name: 'P4',
    value: 'P4',
  },
  P5: {
    id: 1,
    name: 'P5',
    value: 'P5',
  },
  P6: {
    id: 1,
    name: 'P6',
    value: 'P6',
  },
  P7: {
    id: 1,
    name: 'P7',
    value: 'P7',
  },
};

const RELEASE_CATEGORY_OPTION = {
  UI_UX: {
    id: 0,
    name: 'UI/UX',
    value: 'UI/UX',
  },
  FEATURE: {
    id: 1,
    name: 'FEATURE',
    value: 'FEATURE',
  },
  BUG: {
    id: 2,
    name: 'BUG',
    value: 'BUG',
  },
};
const RELEASE_OPTION = {
  DRAFT: {
    id: 0,
    name: 'DRAFT',
    value: 'true',
  },
  RELEASE: {
    id: 1,
    name: 'RELEASE',
    value: 'false',
  },
};
const KYC_PROVIDER = [
  {
    id: 1,
    name: 'Ahrvo',
    value: 'ahrvo',
    icon: ahrvo,
  },
  { id: 2, name: 'Synaps', value: 'synaps', icon: synaps },
  {
    id: 3,
    name: 'Fractal',
    value: 'fractal',
    icon: fractal,
  },
];

const FORM_OPEN_MODE = {
  VIEW: 'View',
  EDIT: 'Edit',
  CREATE: 'Create',
};

const CSV_DATA = {
  FILENAME: 'smartContract',
  BUTTON_TEXT: 'Download as CSV',
};

const STORAGE_NAME = 'Polytrade';
const DOLLAR_SYMBOL = '$';

export const ENV_CONSTANTS = {
  PRODUCTION_ENV,
  DEVELOPMENT_ENV,
  CURRENT_ENV,
  SERVER_URL,
  REDIRECT_URL,
  APP_NAME,
  DRAWER_WIDTH,
  FAVICON,
  DUMMY_USER,
  STORAGE_NAME,
  NOTIFICATION_STATUS,
  FORM_OPEN_MODE,
  KYC_STATUS_OPTION,
  KYC_PROVIDER,
  DOLLAR_SYMBOL,
  CSV_DATA,
  RELEASE_CATEGORY_OPTION,
  RELEASE_OPTION,
  INVOICE_STATUS_OPTION,
  POLYGON_SCAN,
  POOL_ID_OPTION,
};
