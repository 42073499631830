const isEmailValid = value => {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (value.match(validRegex)) {
    return true;
  }
  return false;
};

const handleEmail = () => {
  if (isEmailValid(REQUIRED.ENTERED_VALUE)) {
    //
  } else {
    setError(true);
    setErrorMessage(RULES.EMAIL.message);
  }
};

const handleRequired = () => {
  if (REQUIRED.ENTERED_VALUE !== '') {
    //
  } else {
    setError(true);
    setErrorMessage(RULES.REQUIRED.message);
  }
};

/**
 * External values we will need
 * @constant {any}
 */
const REQUIRED = {
  STORE: {},
  ENTERED_VALUE: '',
};

/**
 * Will wrap all rules included by user to be checked
 * @constant {any[]}
 */
const RULES_TO_TEST = [];

/**
 * Wrap the errors object belongs to mistakes is setting the validation rules
 * Hold:
 * 1- MESSAGES: String array of error messages
 * 2- IS_ERROR: Boolean to refer to the error status
 * @constant {any}
 */
const SETUP_ERRORS = {
  MESSAGES: [],
  IS_ERROR: false,
};

/**
 * Add here any new rule to be handled.
 * Each rule should has:
 * 1- Name: String value to check the rule
 * 2- Handler: The function that will handle the validation rule
 * @constant {any[]}
 */
const RULES = {
  EMAIL: {
    id: 'email',
    name: '"Email"',
    message: 'The email is not velid',
    handler: handleEmail,
  },

  REQUIRED: {
    id: 'required',
    name: '"Required"',
    message: 'The field is required',
    handler: handleRequired,
  },
};

/**
 * Wrap the errors object
 * Hold:
 * 1- MESSAGES: String array of error messages
 * 2- IS_ERROR: Boolean to refer to the error status
 * @constant {any}
 */
const ERRORS = {
  MESSAGES: [],
  IS_ERROR: false,
};

/**
 * Add here any special character to remove from parameters string
 * @constant {any}
 */
const CHARACTERS_TO_EXCLUDE = {
  FROM_PARAMETERS: [' ', '(', ')'],
};

/**
 * Void
 * Clear RULES_TO_TEST array and remove all items
 *
 * @returns {void}
 */
const clearRules = () => {
  RULES_TO_TEST.length = 0;
};

/**
 * Void
 * Clear RULES_TO_TEST array and remove all items
 *
 * @returns {void}
 */
const clearErrors = () => {
  SETUP_ERRORS.MESSAGES.length = 0;
  ERRORS.IS_ERROR = false;
  SETUP_ERRORS.IS_ERROR = false;
};

/**
 * Perform every clear function
 *
 * @returns {void}
 */
const clearAll = () => {
  clearRules();
  clearErrors();
};

/**
 * Void
 * Set IS_ERROR value/status
 *
 * @returns {void}
 */
const setError = value => {
  ERRORS.IS_ERROR = value;
};

/**
 * Void
 * Add new error message to MESSAGES
 *
 * @returns {void}
 */
const setErrorMessage = value => {
  ERRORS.MESSAGES = [value];
};

/**
 * Void
 * Set the Entered Value by the user ENTERED_VALUE
 * @param {any} store
 *
 * @returns {void}
 */
export const setEnteredValue = value => {
  REQUIRED.ENTERED_VALUE = value;
};

/**
 * Void
 * Set the store - One of the external required values we need
 * @param {any} store
 *
 * @returns {void}
 */
export const setValidationStore = value => {
  REQUIRED.STORE = value;
};

/**
 * Void
 * Check all RULE_TO_TEST and call the proper validation function
 * Each function set the proper error message if any
 *
 * @returns {void}
 */
const handleAll = () => {
  RULES_TO_TEST.forEach(ruleToClear => {
    Object.values(RULES).forEach(rule => {
      if (ruleToClear.id === rule.id) {
        rule.handler(ruleToClear.parameters);
      }
    });
  });
  if (SETUP_ERRORS.IS_ERROR) {
    return SETUP_ERRORS;
  }
  return ERRORS;
};

/**
 * Void
 * Check the rules string coming from the input
 * Structure the rule in array in a standard way
 * Store the rule in RULE_TO_TEST
 * Validate all RULE_TO_TEST
 * @param {string} rules
 *
 * @returns {void}
 */
export const validate = (rules = '', enteredValue = '') => {
  clearAll();
  setEnteredValue(enteredValue);

  const validations = rules.split('|');

  const allRules = [];

  validations.forEach(rule => {
    if (rule.includes(':')) {
      const colonIndex = rule.indexOf(':');
      const ruleId = rule.slice(0, Math.max(0, colonIndex)).toLowerCase();

      let parametersString = rule.slice(Math.max(0, colonIndex + 1));
      CHARACTERS_TO_EXCLUDE.FROM_PARAMETERS.forEach(char => {
        parametersString = parametersString.replace(char, '');
      });
      const parameters = parametersString.split(',');

      const structuredRule = {
        id: ruleId,
        parameters,
      };
      allRules.push(structuredRule);
    } else {
      allRules.push({ id: rule.toLowerCase(), parameters: [] });
    }
  });

  const uniqueRules = [
    ...new Map(allRules.map(item => [item.id, item])).values(),
  ];

  uniqueRules.forEach(rule => RULES_TO_TEST.push(rule));

  return handleAll();
};
