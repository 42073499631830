import { ENV_CONSTANTS } from './Constants';

/**
 * Define the common constants along all the application.
 */
const { APP_NAME, BRAND_LOGO, FAVICON } = ENV_CONSTANTS;

const NOTIFICATION_AUTO_CLOSE_MS = 2500;

const BUTTON_TEXT = {
  GO_TO_HOME: 'Go To Home',
};

const PAGE_TITLE = {
  MINT_INVOICE: 'MINT INVOICE',
};

const TO_FIXED_DECIMALS = 2;
const USDC_DECIMALS = 6;
const TRADE_MATIC_DECIMALS = 18;

const PAGINATION_LIMIT = 10;
const PAGINATION_NUMBER = 1;
/**
 * Export all constants in one to deal with them by the name of COMMON.
 * @constant {Object} COMMON .
 */
// Export all constants in one to deal with them by the name of COMMON
export const COMMON = {
  APP_NAME,
  BRAND_LOGO,
  FAVICON,
  NOTIFICATION_AUTO_CLOSE_MS,
  BUTTON_TEXT,
  TO_FIXED_DECIMALS,
  USDC_DECIMALS,
  TRADE_MATIC_DECIMALS,
  PAGE_TITLE,
  PAGINATION_LIMIT,
  PAGINATION_NUMBER,
};
