import { createContext, useReducer, useMemo } from 'react';
import { APP_DATA } from '../Configs/AppData';
import appDataReducer from '../Reducers/AppData/AppData';

/**
 * Default values that will be loaded once the application opened.
 * @constant {Theme} defaultTheme .
 */
const defaultTheme = APP_DATA.LIGHT_THEME;

/**
 * Initial state will be dispatched.
 * @constant {AppDataState} appDataInitialState .
 */
const appDataInitialState = {
  appData: { theme: defaultTheme, isDrawerOpen: true },
};

/**
 * Create and export the context that will wrap all the application.
 * @constant {React.Context} AppDataContext .
 */
export const AppDataContext = createContext({
  state: appDataInitialState,
  dispatch: () => null,
});

/**
 * Return a provider to manage the state of the application
 * @returns {JSX.Element} AppDataProvider
 * Function to create a provider to wrap all the application
 * Export the provider that we will use in need of context props
 * @param {Node} children .
 */
export const AppDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appDataReducer, appDataInitialState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <AppDataContext.Provider value={value}>{children}</AppDataContext.Provider>
  );
};
