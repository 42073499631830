import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';

//All API request call apiHandler (helper function)

/**
 *
 * @param {*} data
 * @returns access token and Admin user details
 */
export const userLogin = async data => {
  try {
    const url = `${ROUTE_NAMES.LOGIN_ADMIN}`;
    return await apiHandler(REQUEST_METHODS.POST, url, {}, {}, data);
  } catch (error) {
    return error;
  }
};
