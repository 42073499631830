import { COMMON } from './Common';

/**
 * Hold all messages used in the application.
 * @constant {string} WELCOME_MESSAGE .
 */
const WELCOME_MESSAGE = `Welcome to ${COMMON.APP_NAME}`;
const NOT_FOUND_MESSAGE = 'No page found,please try again';
const SIGN_IN_MESSAGE =
  'Please sign-In to your account and start the administration';
const TRADE_MESSAGE = 'Shaping the future of Trade Finance';
const LOGING = 'Handling login process';
const LOGIN_ERROR = 'Error! Failed to login';
const CREATE_NOTIFICATION_SUCCESS = 'Create notification successful';
const CREATE_KYC_SUCCESS = 'Kyc verified successful';
const EDIT_NOTIFICATION_SUCCESS = 'Edit notification successful';
const DELETE_NOTIFICATION_SUCCESS = 'Delete notification successful';
const SOME_ERROR = 'Something went wrong!';
const CREATE_RELEASE_SUCCESS = 'Create release successful';
const MINT_SUCCESS = 'Invoice is Successfully Minted';
const NOT_FOUND = 'Page Not Found';
const NOT_FOUND_CONTENT =
  'The page you are looking for was moved, removed, renamed or might never have existed!';

/**
 * Export all the messages in one constant.
 * @constant {Object} MESSAGES .
 */
export const MESSAGES = {
  WELCOME_MESSAGE,
  NOT_FOUND_MESSAGE,
  SIGN_IN_MESSAGE,
  TRADE_MESSAGE,
  LOGING,
  LOGIN_ERROR,
  CREATE_NOTIFICATION_SUCCESS,
  EDIT_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_SUCCESS,
  SOME_ERROR,
  NOT_FOUND,
  NOT_FOUND_CONTENT,
  CREATE_KYC_SUCCESS,
  CREATE_RELEASE_SUCCESS,
  MINT_SUCCESS,
};
