import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { setValidationStore, validate } from '../../../Helpers/Validator';
import styles from './Styles';

/**
 * Component to use as an Atom in the application.
 * This component create a Text Input with many options.
 *
 * @component
 * @param {string} input
 * @param {Function} inputHandler
 * @param {boolean} required
 * @param {boolean} disabled
 * @param {any} featuredValue
 * @param {any} suffixAction
 * @param {string} title
 * @param {string} placeholder
 * @param {string} rules
 * @param {boolean} isValid
 * @param {boolean} type
 */
export const CustomInput = ({
  input,
  inputHandler,
  required,
  disabled,
  featuredValue = null,
  suffixAction = null,
  title,
  rules = 'Enter New Custom Input',
  placeholder,
  isValid = 'text',
  type,
  name,
  min = null,
  inputStyle,
  inputAs = 'input',
  rows = 1,
}) => {
  const store = useSelector(state => state);

  const [hasError, setHasError] = useState(false);
  const [errors, setErrors] = useState('');

  const handleInput = event => {
    inputHandler(event.target.name, event.target.value);
    const errors = validate(rules, event.target.value);
    setHasError(errors.IS_ERROR);
    setErrors(errors.MESSAGES);
    isValid(name, !errors.IS_ERROR);
  };

  useEffect(() => {
    setValidationStore(store);
  }, [store]);

  return (
    <Form.Group style={styles.container()}>
      {title && <Form.Label style={styles.title()}>{title}</Form.Label>}
      {featuredValue !== null && (
        <Form.Label style={styles.featured()}>
          {featuredValue.title}: <b>{featuredValue.value}</b>
        </Form.Label>
      )}
      {suffixAction !== null && (
        <Form.Label style={styles.suffix()} onClick={suffixAction.action}>
          {suffixAction.title && suffixAction.title.toUpperCase()}
          {suffixAction.icon && suffixAction.icon}
        </Form.Label>
      )}
      <Form.Control
        required={required}
        disabled={disabled}
        name={name}
        as={inputAs}
        rows={rows}
        min={min}
        type={type}
        value={input}
        placeholder={placeholder}
        style={{ ...styles.input(hasError), ...inputStyle }}
        onChange={handleInput}
      />
      {hasError && (
        <div style={styles.errorSection()}>
          <Form.Label>{errors.join(', ')}</Form.Label>
        </div>
      )}
    </Form.Group>
  );
};
