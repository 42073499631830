import { useState } from 'react';

import { ENV_CONSTANTS } from '../../../Configs/Constants';
import { CustomButton } from '../../Atoms/Buttons/CustomButton';
import { KycForm } from './Components/KycForm/KycForm';
import { CustomModal } from '../../Modals/CustomModal/CustomModal';
import { styles } from './Styles';

/**
 * Component to be the first component to show.
 *
 * @component
 */
export const Kyc = () => {
  const [kycModalShow, setKycModalShow] = useState(false);
  const [kycFormMode, setKycFormMode] = useState();

  const closeNotificationModal = () => {
    setKycModalShow(false);
  };

  const onCreateNotification = () => {
    setKycFormMode(ENV_CONSTANTS.FORM_OPEN_MODE.CREATE);
    setKycModalShow(true);
  };

  return (
    <div className="mb-2 p-5">
      <div className="d-flex justify-content-end">
        <div style={styles.createButtonView()}>
          <CustomButton
            type="secondary"
            title="Create Kyc"
            handler={onCreateNotification}
          />
        </div>
      </div>

      <CustomModal display={kycModalShow} handleClose={closeNotificationModal}>
        <KycForm mode={kycFormMode} />
      </CustomModal>
    </div>
  );
};
