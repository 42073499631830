import { useContext, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ENV_CONSTANTS } from '../../../../../Configs/Constants';

import { AppDataContext } from '../../../../../Contexts/AppData';
import { CustomButton } from '../../../../Atoms/Buttons/CustomButton';
import { CustomInput } from '../../../../Atoms/Input/CustomInput';
import { CustomSelect } from '../../../../Atoms/Select/CustomSelect';
import { updateKycAction } from './Actions';
import { styles } from './Styles';

/**
 * Component to contains many wallets ways of connections.
 *
 * @component
 */
export const KycForm = ({ handleClose, mode }) => {
  const initialValidation = {
    address: false,
  };
  const initialKycData = {
    status: true,
    provider: ENV_CONSTANTS.KYC_PROVIDER[0].value,
  };

  const { state: appDataState } = useContext(AppDataContext);
  const [kycData, setKycData] = useState(initialKycData);
  const [formValidation, setFormValidation] = useState(initialValidation);
  const [loading, setLoading] = useState(false);

  const canEdit =
    mode === ENV_CONSTANTS.FORM_OPEN_MODE.CREATE ||
    mode === ENV_CONSTANTS.FORM_OPEN_MODE.EDIT;

  const handleChangeForm = (key, value) => {
    setKycData({
      ...kycData,
      [key]: value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    switch (mode) {
      case ENV_CONSTANTS.FORM_OPEN_MODE.CREATE:
        await updateKycAction(kycData);
        setLoading(false);
        handleClose(true);
        return true;

      default:
        return false;
    }
  };

  return (
    <>
      <h6 style={styles.titleText(appDataState.appData.theme)}>
        {`${mode} Kyc`}
      </h6>

      <CustomInput
        required
        disabled={!canEdit}
        input={kycData?.address}
        inputHandler={handleChangeForm}
        placeholder="Wallet Address"
        title="Wallet Address"
        name="address"
        rules="required"
        inputStyle={styles.inputStyle(appDataState.appData.theme)}
        isValid={(name, isValid) =>
          setFormValidation({ ...formValidation, [name]: isValid })
        }
      />

      <CustomSelect
        disabled={!canEdit}
        inputHandler={handleChangeForm}
        name="status"
        title="Status"
        value={kycData?.status}
        options={ENV_CONSTANTS.KYC_STATUS_OPTION}
        inputStyle={styles.inputStyle(appDataState.appData.theme)}
      />
      <div className="d-flex justify-content-between mt-4 mb-4">
        {ENV_CONSTANTS.KYC_PROVIDER.map(item => {
          return (
            <Button
              key={item.id}
              style={styles.providerButton()}
              onClick={() => handleChangeForm('provider', item.value)}
            >
              <Form.Check
                type="radio"
                checked={kycData?.provider === item.value}
                id={item.id}
                label=""
              />
              {item.icon && (
                <img src={item.icon} alt={item.name} width={58} height={58} />
              )}
            </Button>
          );
        })}
      </div>
      <div className="mt-4">
        {canEdit && (
          <CustomButton
            type="secondary"
            title="Submit"
            handler={handleSubmit}
            disabled={!Object.values(formValidation).every(value => value)}
            isLoading={loading}
          />
        )}
      </div>
    </>
  );
};
