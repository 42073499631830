/**
 * Return styles for Custom Input Group container
 * @returns {CSSProperties}
 */
const container = () => {
  const style = { margin: '12px 0', padding: 0, position: 'relative' };
  return style;
};

/**
 * Return styles for Custom Input box
 * @returns {CSSProperties}
 */
const input = hasError => {
  const style = {
    borderRadius: '4px',
    fontSize: '0.9em',
    paddingRight: '40px',
    borderColor: hasError ? '#e94242' : '#eceff4',
    backgroundColor: '#212121',
    outline: 'none',
    color: '#fff',
  };
  return style;
};

/**
 * Return styles for Custom Input Title
 * @returns {CSSProperties}
 */
const title = () => {
  const style = {
    margin: 0,
    fontSize: '0.7em',
    fontWeight: 500,
    backgroundColor: 'white',
    padding: '3px',
    position: 'absolute',
    top: -10,
    left: 10,
  };
  return style;
};

/**
 * Return styles for Custom Input Featured Value
 * @returns {CSSProperties}
 */
const featured = () => {
  const style = {
    margin: 0,
    fontSize: '0.6em',
    color: '#777777',
    position: 'absolute',
    top: -15,
    right: 15,
  };
  return style;
};

/**
 * Return styles for Custom Input Suffix Action Button
 * @returns {CSSProperties}
 */
const suffix = () => {
  const style = {
    margin: 0,
    fontSize: '0.7em',
    color: '#768cfa',
    position: 'absolute',
    top: 7,
    right: 15,
    cursor: 'pointer',
  };
  return style;
};

const errorSection = () => {
  const style = {
    margin: 0,
    fontSize: '0.7em',
    fontWeight: 600,
    color: '#e94242',
    padding: '2px 6px',
  };

  return style;
};

const styles = { container, input, title, featured, suffix, errorSection };

export default styles;
