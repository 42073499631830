import { Children, cloneElement, isValidElement, useCallback } from 'react';
import { Modal, Container } from 'react-bootstrap';
import { CloseButton } from '../../Atoms/Buttons/CloseButton/CloseButton';
import { styles } from './Styles';

/**
 * Component to contains many wallets ways of connections.
 *
 * @component
 */
export const CustomModal = ({ display, handleClose, children }) => {
  const handleCloseModal = useCallback(
    withSuccess => {
      if (withSuccess === null) {
        handleClose();
      } else {
        handleClose({ withSuccess });
      }
    },
    [handleClose],
  );

  /**
   * In case we decided to pass a prop to the children
   */
  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, { handleClose: handleCloseModal });
    }

    return child;
  });

  return (
    <Modal
      centered
      show={display}
      size="xl"
      backdrop="static"
      keyboard={false}
      className="customModal"
      onHide={handleClose}
    >
      <div>
        {/* Finishing the close button*/}
        <CloseButton handleClose={handleClose} />
      </div>
      <Modal.Body style={styles.modalBody()}>
        <Container>{childrenWithProps}</Container>
      </Modal.Body>
    </Modal>
  );
};
