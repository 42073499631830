import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { AppDataContext } from '../../Contexts/AppData';
import { Home } from '../Templates/Home/Home';
import { Notifications } from '../Templates/Notifications/Notifications';
import { LoginPage } from '../Organisms/LoginPage/LoginPage';
import { NotFound } from '../General/NotFound';
import { URLS } from '../../Configs/FrontendUrls';
import { styles } from './Styles';
import { Kyc } from '../Templates/Kyc/Kyc';
import { Release } from '../Templates/Release/Release';
import { MintInvoice } from '../Templates/MintInvoice/MintInvoice';
import { PrivateRoute } from './PrivateRoute';
/**
 * Routes Component to store the routes for navigation in the MainLayout
 *
 * @component
 */
export const RoutesContent = () => {
  // Get use of AppDataContext that holds the application needed data
  const { state: appDataState } = useContext(AppDataContext);

  return (
    <Container
      fluid="true"
      style={styles.mainContainer(appDataState.appData.theme)}
    >
      <Routes>
        <Route exact path={URLS.URL.INITIAL_PATH} element={<PrivateRoute />}>
          <Route path={URLS.URL.INITIAL_PATH} element={<Home />} />
          <Route
            exact
            path={URLS.URL.NOTIFICATION}
            element={<Notifications />}
          />
          <Route path={URLS.URL.KYC} element={<Kyc />} />
          <Route path={URLS.URL.RELEASE} element={<Release />} />
          <Route path={URLS.URL.MINT_INVOICE} element={<MintInvoice />} />
        </Route>

        <Route path={URLS.URL.LOGIN} element={<LoginPage />} />
        <Route path={URLS.URL.DEFAULT} element={<NotFound />} />
      </Routes>
    </Container>
  );
};
