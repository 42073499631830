import { Col, Container, Row } from 'react-bootstrap';
import { MESSAGES } from '../../Configs/Messages';
import { CustomButton } from '../Atoms/Buttons/CustomButton';
import NotFoundLightImg from '../../Assets/notFoundLight.png';
import { URLS } from '../../Configs/FrontendUrls';
import { COMMON } from '../../Configs/Common';
import { styles } from './Styles';
/**
 * Default  Component to be shown when no route is matched.
 *
 * @component
 */
export const NotFound = () => {
  return (
    <Container className="text-center">
      <Row style={styles.row()}>
        <Col xs="12">
          <h1 style={styles.h1()}>{MESSAGES.NOT_FOUND}</h1>
        </Col>
      </Row>
      <Row style={styles.row()}>
        <Col xs="12">
          <p style={styles.paragraph()}>{MESSAGES.NOT_FOUND_CONTENT}</p>
        </Col>
      </Row>
      <Row style={styles.row()}>
        <Col xs="2" style={styles.notFoundButton()}>
          <CustomButton
            type="secondary"
            title={COMMON.BUTTON_TEXT.GO_TO_HOME}
            className="buttonLightStyle"
            to={URLS.URL.INITIAL_PATH}
          />
        </Col>
      </Row>
      <Row style={styles.row()}>
        <Col xs="12">
          <img alt="Not Found" src={NotFoundLightImg} />
        </Col>
      </Row>
    </Container>
  );
};
