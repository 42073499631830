/**
 * Return styles for pagination
 * @returns {CSSProperties}
 */
function container() {
  const style = {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '5%',
    marginBottom: ' 5%',
  };
  return style;
}

export const styles = { container };
