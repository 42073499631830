import { ENV_CONSTANTS } from '../../Configs/Constants';

/**
 * Return styles for main container
 * @returns {CSSProperties} style
 * RoutesContent style will be used in MainLayout to hold individual routes.
 * @param {Theme} theme .
 */
function mainContainer(theme) {
  const style = {
    height: '100%',
    margin: 0,
    padding: '0',
    backgroundColor: theme.primary.backgroundColor,
    color: theme.primary.textColor,
    textAlign: 'center',
  };
  return style;
}

/**
 * Return styles for main container
 * @returns {CSSProperties} style
 * MainLayout styles use the theme to set the proper colors.
 * MainContainer style will hold all other components.
 * @param {Theme} theme .
 */
function outerContainer() {
  const style = {
    display: 'flex',
  };
  return style;
}

function mainWrapper(isDrawerOpen) {
  const style = {
    flex: '1 1 auto',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    paddingLeft: isDrawerOpen ? ENV_CONSTANTS.DRAWER_WIDTH : 0,
    transition: 'all 0.5s ease-in-out',
  };
  return style;
}

export const styles = { outerContainer, mainWrapper, mainContainer };
