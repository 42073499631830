import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { ENV_CONSTANTS } from '../../../../../Configs/Constants';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { CustomButton } from '../../../../Atoms/Buttons/CustomButton';
import { CustomInput } from '../../../../Atoms/Input/CustomInput';
import { styles } from './Styles';
import { createRelease, editRelease } from '../../../../../Apis/ReleaseService';
import { MESSAGES } from '../../../../../Configs/Messages';
import { MultipleSelect } from '../../../../Atoms/MultipleSelect/MultipleSelect';
import { RichTextEditor } from '../../../../Atoms/RichTextEditor/RichTextEditor';
import { upload } from '../../../../../Apis/fileApi';

/**
 * Component for Release Form .
 * @param {object} data
 * @param {object} handleClose
 * @param {object} mode
 * @returns
 */
export const ReleaseForm = ({ data, handleClose, mode }) => {
  const initialValidation = {
    title: mode === ENV_CONSTANTS.FORM_OPEN_MODE.EDIT,
    version: mode === ENV_CONSTANTS.FORM_OPEN_MODE.EDIT,
  };
  const initialData = {
    ...data,
    tag: data?.tag,
    is_draft: data?.is_draft || ENV_CONSTANTS.RELEASE_OPTION.DRAFT.value,
  };
  const { state: appDataState } = useContext(AppDataContext);
  const [releaseData, setReleaseData] = useState(initialData);
  const [formValidation, setFormValidation] = useState(initialValidation);
  const [loading, setLoading] = useState(false);

  const canEdit =
    mode === ENV_CONSTANTS.FORM_OPEN_MODE.CREATE ||
    mode === ENV_CONSTANTS.FORM_OPEN_MODE.EDIT;

  const handleChangeForm = (name, value) => {
    setReleaseData({
      ...releaseData,
      [name]: value,
    });
  };

  /**
   * function to uploadDocument and then set the states.
   * @param {object} e
   */
  const handleFileUpload = async e => {
    const data = await uploadDocument(e.target.files[0]);
    if (data) {
      setReleaseData({
        ...releaseData,
        [e.target.name]: data.url,
      });
    }
  };

  /**
   * function to call upload api
   * @param {object} file
   * @returns
   */
  const uploadDocument = async file => {
    const fileData = {};
    const formData = new FormData();
    formData.append('file', file);
    const uploadedFile = await upload(formData);
    fileData.url = uploadedFile.file_url;
    return fileData;
  };

  /**
   * onChange function for multi select option
   * @param {array} options
   */
  const selectedTags = options => {
    const status = [];
    Object.values(options).map(data => status.push(data.value));
    setReleaseData({
      ...releaseData,
      tag: status,
    });
  };

  /**
   * Function for handle submit based on form type
   * @returns
   */
  const handleSubmit = async () => {
    setLoading(true);
    switch (mode) {
      case ENV_CONSTANTS.FORM_OPEN_MODE.CREATE:
        await createReleaseAction(releaseData);
        setLoading(false);
        handleClose(true);
        return true;

      case ENV_CONSTANTS.FORM_OPEN_MODE.EDIT:
        await editReleaseAction(releaseData);
        setLoading(false);
        handleClose(true);
        return true;

      default:
        return false;
    }
  };

  /**
   * Function to call create release api
   * @param {object} data
   * @returns
   */
  const createReleaseAction = async data => {
    const response = await createRelease(data);
    if (!response.error) {
      toast.success(MESSAGES.CREATE_RELEASE_SUCCESS);
      return true;
    }
    if (response.data.errors) {
      response.data.errors.forEach(error => {
        toast.error(error.msg);
      });
    }
    return false;
  };

  /**
   * function to call edit release api
   * @param {ObjectID} data
   * @returns
   */
  const editReleaseAction = async data => {
    const response = await editRelease(data);
    if (!response.error) {
      toast.success(MESSAGES.EDIT_NOTIFICATION_SUCCESS);
      return true;
    }
    toast.error(MESSAGES.SOME_ERROR);
    return false;
  };
  return (
    <>
      <h6 style={styles.titleText(appDataState.appData.theme)}>
        {`${mode} Release`}
      </h6>
      <CustomInput
        required
        disabled={!canEdit}
        input={releaseData?.title}
        inputHandler={handleChangeForm}
        placeholder="Title"
        title="Title"
        name="title"
        rules="required"
        inputStyle={styles.inputStyle(appDataState.appData.theme)}
        isValid={(name, isValid) =>
          setFormValidation({ ...formValidation, [name]: isValid })
        }
      />
      <RichTextEditor
        value={releaseData?.text}
        onChange={data => setReleaseData({ ...releaseData, text: data })}
      />
      <Form.Control
        onChange={handleFileUpload}
        size="lg"
        accept="image/jpg, image/jpeg, image/png"
        multiple
        type="file"
        className="mt-3"
        name="image_url"
      />
      {releaseData.image_url && (
        <img
          alt="img"
          width="200px"
          className="mt-4"
          height="200px"
          src={releaseData?.image_url}
        />
      )}
      <MultipleSelect
        disabled={!canEdit}
        inputHandler={selectedTags}
        title="Category"
        value={releaseData?.tag}
        options={ENV_CONSTANTS.RELEASE_CATEGORY_OPTION}
      />
      <div className="mt-4">
        <CustomInput
          required
          disabled={!canEdit}
          input={releaseData?.version}
          inputHandler={handleChangeForm}
          placeholder="Version"
          name="version"
          rules="required"
          inputStyle={styles.inputStyle(appDataState.appData.theme)}
          isValid={(name, isValid) =>
            setFormValidation({ ...formValidation, [name]: isValid })
          }
          title="Version"
        />
      </div>
      <div className="mt-4">
        {canEdit && (
          <CustomButton
            type="secondary"
            title="Submit"
            handler={handleSubmit}
            disabled={!Object.values(formValidation).every(value => value)}
            isLoading={loading}
          />
        )}
      </div>
    </>
  );
};
