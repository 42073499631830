import { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { getInvoicesData } from '../../../Apis/InvoiceApi';
import { InvoiceForm } from './Components/InvoiceForm/InvoiceForm';
import { CustomButton } from '../../Atoms/Buttons/CustomButton';
import { CustomModal } from '../../Modals/CustomModal/CustomModal';
import { styles } from './Styles';
import { InvoiceCard } from './Components/InvoiceCard/InvoiceCard';
import { ENV_CONSTANTS } from '../../../Configs/Constants';
import { HELPERS } from '../../../Helpers';
import { Pagination } from '../../Atoms/Pagination/Pagination';
import { COMMON } from '../../../Configs/Common';

/**
 * Component to be the first component in Mint Invoice.
 *
 * @component
 */
export const MintInvoice = () => {
  const [invoiceData, setInvoiceData] = useState(ENV_CONSTANTS.EMPTY_ARRAY);
  const [showInvoiceForm, setShowInvoiceForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(COMMON.PAGINATION_NUMBER);
  const [totalPages, setTotalPages] = useState(null);

  /**
   * function to call get invoices api.
   */
  const getInvoices = useCallback(
    pageNumber => {
      const paginationOption = {
        currentPage: pageNumber || currentPage,
        dataLimit: COMMON.PAGINATION_LIMIT,
      };

      getInvoicesData(paginationOption).then(response => {
        if (response?.data) {
          setInvoiceData(response?.data?.docs);
          setTotalPages(response.data?.totalPages);
        }
      });
    },
    [currentPage],
  );

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  return (
    <div className="mb-2 p-5">
      <div className="d-flex justify-content-end">
        <div style={styles.createButtonView()}>
          <CustomButton
            type="secondary"
            title="Mint Invoice"
            handler={() => setShowInvoiceForm(true)}
          />
        </div>
      </div>
      <div>
        <Row>
          {invoiceData &&
            invoiceData?.map(invoice => (
              <Col md={4} style={styles.invoiceCard()}>
                <InvoiceCard invoice={invoice} />
              </Col>
            ))}
        </Row>
        {!HELPERS.isEmpty(invoiceData) && (
          <div>
            <Pagination
              totalPages={totalPages}
              handlePageChange={setCurrentPage}
            />
          </div>
        )}
      </div>
      <CustomModal
        display={showInvoiceForm}
        handleClose={() => setShowInvoiceForm(false)}
      >
        <InvoiceForm />
      </CustomModal>
    </div>
  );
};
