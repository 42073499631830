import { ethers } from 'ethers';

/**
 * Helper function to check whether a value is empty(null, undefined, empty string, empty array, empty object)
 * @param {*} value
 * @returns {boolean}
 */

const isEmpty = value => {
  if (
    // Undefined
    typeof value === 'undefined' ||
    // String
    (typeof value === 'string' && value.trim().length === 0) ||
    // Null
    getType(value) === '[object Null]' ||
    // Empty object
    (getType(value) === '[object Object]' && Object.keys(value).length === 0) ||
    // Empty array
    (getType(value) === '[object Array]' && value.length === 0)
  ) {
    return true;
  }

  return false;
};

/**
 * Helper function to check when a value is  not empty
 * @param {*} value
 * @returns {boolean}
 */
const isNotEmpty = value => !isEmpty(value);

/**
 *Returns the type of the element
 * @param {*} value
 * @returns value
 */
const getType = value => Object.prototype.toString.call(value);

/**
 *
 * @param {*} element
 * @returns boolean
 */
const isArray = element => {
  if (getType(element) === '[object Array]') {
    return true;
  }

  return false;
};

/**
 *
 * @param {*} element
 * @returns boolean
 */
const isObject = element => {
  if (isNotEmpty(element) && getType(element) === '[object Object]') {
    return true;
  }

  return false;
};

/**
 * Function to build response object
 * @param {*} response
 * @returns response with status code,data,message and error
 */
const responseHandler = response => {
  return {
    status: response.status || '',
    data: response.data || {},
    message: response.message || '',
    error: response.error || '',
  };
};

/**
 * Function for format number in currency
 * @param {*} string
 * @returns string
 */
const formatInCurrency = number => Number(number).toLocaleString('en-US');

/**
 *
 * @param {*} element
 * @returns boolean
 */
const isEmptyArray = element => {
  if (getType(element) === '[object Array]') {
    return true;
  }

  return false;
};

/**
 * Format timestamps in DD/MM/YYYY format
 * @param {*} string
 * @returns Date
 */
const formatDate = date => {
  const formattedDate = new Date(date).toISOString().split('T')[0];
  return formattedDate;
};

/**
 * Todo: to convert to Decimal
 * @param {*} value
 * @param {*} decimal
 * @returns
 */
const toDecimal = (value, decimal) =>
  Number(ethers.utils.formatUnits(value, decimal));

/**
 * Helper To parse html
 * @param {*} html
 * @returns
 */
const parseHtml = html => {
  const strippedHtml = html?.replace(/<[^>]+>/g, '');
  return strippedHtml;
};

/**
 * Helper to format Array
 * @param {*} data
 * @returns
 */
const formatArray = data => {
  const formattedArray = data?.join(', ');
  return formattedArray;
};
export const HELPERS = {
  isEmpty,
  isNotEmpty,
  getType,
  isArray,
  isObject,
  responseHandler,
  formatInCurrency,
  isEmptyArray,
  formatDate,
  toDecimal,
  parseHtml,
  formatArray,
};
