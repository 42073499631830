/**
 * Return styles for sub title test
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function mainCard(theme) {
  const style = {
    backgroundColor: theme.common.white,
    height: 400,
    width: 640,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for by title Text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function titleText() {
  const style = {
    fontWeight: '600',
    fontSize: 24,
    height: 52,
    textAlign: 'center',
  };
  return style;
}

/**
 * Return styles for sub title test
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function inputStyle(theme) {
  const style = {
    backgroundColor: theme.common.white,
    color: theme.common.black,
    border: `1px solid ${theme.primary.gray}`,
    marginTop: 22,
    height: 52,
  };
  return style;
}

/**
 * Return styles for sub title test
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function textAreaStyle(theme) {
  const style = {
    backgroundColor: theme.common.white,
    color: theme.common.black,
    border: `1px solid ${theme.primary.gray}`,
    height: 120,
  };
  return style;
}

/**
 * Return styles for sub title test
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function providerButton() {
  const style = {
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
  };
  return style;
}

export const styles = {
  mainCard,
  titleText,
  inputStyle,
  textAreaStyle,
  providerButton,
};
