/**
 * Return styles for the Modal Body
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function modalBody() {
  const style = { padding: '50px' };
  return style;
}

export const styles = { modalBody };
