import { ICONS } from './IconsConstants';
import { EditIcon } from './EditIcon';
import { ViewIcon } from './ViewIcon';
import { DeleteIcon } from './DeleteIcon';
import { NotificationBell } from './NotificationBell';
import { HomeIcon } from './HomeIcon';
import { WalletIcon } from './WalletIcon';
import { MintInvoice } from './MintInvoice';

export const SvgElementWrapper = ({
  name,
  height,
  width,
  fillColor,
  className,
}) => {
  switch (name) {
    case ICONS.EDIT:
      return (
        <EditIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.VIEW:
      return (
        <ViewIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.DELETE:
      return (
        <DeleteIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );

    case ICONS.NOTIFICATION_BELL:
      return (
        <NotificationBell
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );

    case ICONS.HOME:
      return (
        <HomeIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );

    case ICONS.WALLET:
      return (
        <WalletIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );

    case ICONS.MINT_INVOICE:
      return (
        <MintInvoice
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );

    default:
      return null;
  }
};
