import moment from 'moment';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { CustomTable } from '../../../../Atoms/CustomTable/CustomTable';
import { SvgElementWrapper } from '../../../../Molecules/SvgElementWrapper';
import { ICONS } from '../../../../Molecules/SvgElementWrapper/IconsConstants';
import { styles } from './Styles';

/**
 * Component to be the first component to show.
 *
 * @component
 */
export const NotificationTable = ({
  onEdit,
  onView,
  notificationList,
  onDelete,
}) => {
  const { state: appDataState } = useContext(AppDataContext);

  const columns = [
    {
      name: 'Name',
      dataKey: 'name',
      render: row => {
        return <span>{row.rowData.name}</span>;
      },
    },
    {
      name: 'Description',
      dataKey: 'description',
      render: row => {
        return <span>{row.rowData.description}</span>;
      },
    },
    {
      name: 'Created At',
      dataKey: 'createdAt',
      render: row => {
        return (
          <span>
            {moment(row.rowData.createdAt).format('DD MMM YYYY, hh:mm:ss')}
          </span>
        );
      },
    },
    {
      name: '',
      render: row => {
        return (
          <div className="d-flex justify-content-end">
            <Button onClick={() => onEdit(row)} style={styles.actionButton()}>
              <SvgElementWrapper
                height={24}
                width={24}
                name={ICONS.EDIT}
                fillColor={appDataState.appData.theme.primary.textColor}
              />
            </Button>
            <Button
              onClick={() => onView(row)}
              style={styles.actionButton()}
              className="mx-2"
            >
              <SvgElementWrapper
                height={24}
                width={24}
                name={ICONS.VIEW}
                fillColor={appDataState.appData.theme.primary.textColor}
              />
            </Button>
            <Button
              onClick={() => onDelete(row)}
              style={styles.actionButton()}
              className="mx-2"
            >
              <SvgElementWrapper
                height={24}
                width={24}
                name={ICONS.DELETE}
                fillColor={appDataState.appData.theme.primary.textColor}
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-4">
      {notificationList && (
        <CustomTable columns={columns} data={notificationList} />
      )}
    </div>
  );
};
