import { toast } from 'react-toastify';
import { ACTION_TYPES } from '../Configs/ActionTypes';
import { userLogin } from '../Apis/AuthApi';

//Login action which will dispatch the login action to the reducer
export const login = async (data, dispatch) => {
  try {
    const response = await userLogin(data);
    if (response.error === true) {
      toast.error(response.message);
      return false;
    }
    const action = {
      type: ACTION_TYPES.LOGIN_SUCCESS,
      payload: response.data,
    };
    dispatch(action);
    return true;
  } catch (error) {
    // Will be logged later
  }
  return data;
};

/**
 * Dispatched action to the app.
 * @param {dispatch} dispatch .
 */
export function logout(dispatch) {
  const action = {
    type: ACTION_TYPES.CLEAR_USER_DATA,
    payload: '',
  };
  dispatch(action);
}
