import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppDataContext } from '../../../Contexts/AppData';
import { SvgElementWrapper } from '../../Molecules/SvgElementWrapper';
import { styles } from './Styles';

/**
 * MenuItem will be used to render the links in the sidebar.
 *
 * @component MenuItem
 */
export const MenuItem = ({ element }) => {
  const location = useLocation();
  const { state: appDataState } = useContext(AppDataContext);

  return (
    <div style={styles.menuItem(location.pathname === element.path)}>
      <Link key={element.id} style={styles.menuLink()} to={element.path}>
        <SvgElementWrapper
          name={element.icon}
          height={26}
          width={26}
          fillColor={appDataState.appData.theme.primary.textColor}
        />
        <span style={styles.nameText()}>{element.text}</span>
      </Link>
    </div>
  );
};
