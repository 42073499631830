import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { getReleaseData, publishRelease } from '../../../Apis/ReleaseService';
import { ReleaseTable } from './Components/ReleaseTable/ReleaseTable';
import { ENV_CONSTANTS } from '../../../Configs/Constants';
import { CustomButton } from '../../Atoms/Buttons/CustomButton';
import { CustomModal } from '../../Modals/CustomModal/CustomModal';
import { ReleaseForm } from './Components/ReleaseForm/ReleaseForm';
import { styles } from './Styles';
import { DropdownButton } from '../../Atoms/Dropdown/Dropdown';
import { MESSAGES } from '../../../Configs/Messages';

/**
 * Component to be the first component to in release.
 *
 * @component
 */
export const Release = () => {
  const [releaseModalShow, setReleaseModalShow] = useState(false);
  const [activeReleaseData, setActiveReleaseData] = useState();
  const [releaseFormMode, setReleaseFormMode] = useState();
  const [releaseList, setReleaseList] = useState([]);
  const [title, setTitle] = useState(ENV_CONSTANTS.RELEASE_OPTION.RELEASE.name);

  /**
   * function to call get release api on the basis of release option.
   */
  const getRelease = useCallback(async () => {
    if (title === ENV_CONSTANTS.RELEASE_OPTION.DRAFT.name) {
      getReleaseDataCall(true);
    } else {
      getReleaseDataCall(false);
    }
  }, [title]);

  /**
   * function to call get release data
   * @param {boolean} data
   */
  const getReleaseDataCall = data => {
    getReleaseData(data).then(response => {
      if (response?.data?.docs) {
        setReleaseList(response.data.docs);
      } else {
        setReleaseList([]);
      }
    });
  };

  useEffect(() => {
    getRelease();
  }, [getRelease]);

  const closeReleaseModal = props => {
    if (props.withSuccess) {
      getRelease();
    }
    setActiveReleaseData(null);
    setReleaseModalShow(false);
  };

  const onEditRelease = row => {
    setReleaseFormMode(ENV_CONSTANTS.FORM_OPEN_MODE.EDIT);
    setActiveReleaseData(row.rowData);
    setReleaseModalShow(true);
  };

  const onViewRelease = row => {
    setReleaseFormMode(ENV_CONSTANTS.FORM_OPEN_MODE.VIEW);
    setActiveReleaseData(row.rowData);
    setReleaseModalShow(true);
  };

  const onCreateRelease = () => {
    setReleaseFormMode(ENV_CONSTANTS.FORM_OPEN_MODE.CREATE);
    setReleaseModalShow(true);
  };

  const handleDropdown = data => {
    if (data === ENV_CONSTANTS.RELEASE_OPTION.DRAFT.name) {
      setTitle(ENV_CONSTANTS.RELEASE_OPTION.DRAFT.name);
    } else {
      setTitle(ENV_CONSTANTS.RELEASE_OPTION.RELEASE.name);
    }
  };

  /**
   * function for trigger release from draft.
   * @param {object} data
   */
  const onRelease = data => {
    publishRelease(data.rowData._id).then(response => {
      if (!response.error) {
        toast.success(MESSAGES.CREATE_RELEASE_SUCCESS);
        getRelease();
        return true;
      }
      if (response.data.errors) {
        response.data.errors.forEach(error => {
          toast.error(error.msg);
        });
      }
      return false;
    });
  };

  return (
    <div className="mb-2 p-5">
      <div className="d-flex justify-content-between">
        <DropdownButton
          buttonName="RELEASE OPTION"
          menuItem={ENV_CONSTANTS.RELEASE_OPTION}
          inputHandler={handleDropdown}
        />
        <h1>{title}</h1>
        <div style={styles.createButtonView()}>
          <CustomButton
            type="secondary"
            title="Create New Release"
            handler={onCreateRelease}
          />
        </div>
      </div>
      <ReleaseTable
        onView={onViewRelease}
        onEdit={onEditRelease}
        releaseList={releaseList}
        title={title}
        onRelease={onRelease}
      />
      <CustomModal display={releaseModalShow} handleClose={closeReleaseModal}>
        <ReleaseForm mode={releaseFormMode} data={activeReleaseData} />
      </CustomModal>
    </div>
  );
};
