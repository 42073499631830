import { BrowserRouter as Router } from 'react-router-dom';
import { AppDataProvider } from './Contexts/AppData';
import { MainLayout } from './Components/MainLayout/MainLayout';
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
import Store from './Store/Store';
// import TestButton from './Components/Atoms/Container/Container';

/**
 * Component wrap the whole application unify the layout.
 *
 * @component
 */

const App = () => (
  <Store>
    <ErrorBoundary>
      <AppDataProvider>
        <Router>
          <MainLayout />
        </Router>
      </AppDataProvider>
    </ErrorBoundary>
  </Store>
);
export default App;
