import moment from 'moment';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { CustomTable } from '../../../../Atoms/CustomTable/CustomTable';
import { SvgElementWrapper } from '../../../../Molecules/SvgElementWrapper';
import { ICONS } from '../../../../Molecules/SvgElementWrapper/IconsConstants';
import { styles } from './Styles';
import { ENV_CONSTANTS } from '../../../../../Configs/Constants';
import { HELPERS } from '../../../../../Helpers/index';

/**
 * Component for the Release table.
 * @param {CallableFunction} onEdit
 * @param {CallableFunction} onView
 * @param {object} releaseList
 * @param {string} title
 * @param {CallableFunction} onRelease
 * @returns
 */
export const ReleaseTable = ({
  onEdit,
  onView,
  releaseList,
  title,
  onRelease,
}) => {
  const { state: appDataState } = useContext(AppDataContext);
  const columns = [
    {
      name: 'Title',
      dataKey: 'title',
      render: row => {
        return <span>{row.rowData.title}</span>;
      },
    },
    {
      name: 'Text',
      dataKey: 'text',
      render: row => {
        return <span>{HELPERS.parseHtml(row.rowData.text)}</span>;
      },
    },
    {
      name: 'Category',
      dataKey: 'category',
      render: row => {
        return <span>{HELPERS.formatArray(row?.rowData?.tag)}</span>;
      },
    },
    {
      name: 'Version',
      dataKey: 'version',
      render: row => {
        return <span>{row.rowData.version}</span>;
      },
    },
    {
      name: 'Created At',
      dataKey: 'createdAt',
      render: row => {
        return (
          <span>
            {moment(row.rowData.createdAt).format('DD MMM YYYY, hh:mm:ss')}
          </span>
        );
      },
    },
    {
      name: '',
      render: row => {
        return (
          <div className="d-flex justify-content-end">
            {title === ENV_CONSTANTS.RELEASE_OPTION.DRAFT.name && (
              <Button onClick={() => onEdit(row)} style={styles.actionButton()}>
                <SvgElementWrapper
                  height={24}
                  width={24}
                  name={ICONS.EDIT}
                  fillColor={appDataState.appData.theme.primary.textColor}
                />
              </Button>
            )}
            <Button
              onClick={() => onView(row)}
              style={styles.actionButton()}
              className="mx-2"
            >
              <SvgElementWrapper
                height={24}
                width={24}
                name={ICONS.VIEW}
                fillColor={appDataState.appData.theme.primary.textColor}
              />
            </Button>
            {title === ENV_CONSTANTS.RELEASE_OPTION.DRAFT.name && (
              <Button onClick={() => onRelease(row)}>Release</Button>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-4">
      {releaseList && <CustomTable columns={columns} data={releaseList} />}
    </div>
  );
};
