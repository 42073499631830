/**
 * Return styles for the Modal Body
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function createButtonView() {
  const style = { width: 240 };
  return style;
}

export const styles = { createButtonView };
