import { useContext, useState } from 'react';
import { ENV_CONSTANTS } from '../../../../../Configs/Constants';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { CustomButton } from '../../../../Atoms/Buttons/CustomButton';
import { CustomInput } from '../../../../Atoms/Input/CustomInput';
import { createNotificationAction, editNotificationAction } from './Actions';
import { styles } from './Styles';

/**
 * Component to contains many wallets ways of connections.
 *
 * @component
 */
export const NotificationForm = ({ data, handleClose, mode }) => {
  const initialValidation = {
    name: mode === ENV_CONSTANTS.FORM_OPEN_MODE.EDIT,
    description: mode === ENV_CONSTANTS.FORM_OPEN_MODE.EDIT,
  };

  const { state: appDataState } = useContext(AppDataContext);
  const [notificationData, setNotificationData] = useState(data);
  const [formValidation, setFormValidation] = useState(initialValidation);
  const [loading, setLoading] = useState(false);

  const canEdit =
    mode === ENV_CONSTANTS.FORM_OPEN_MODE.CREATE ||
    mode === ENV_CONSTANTS.FORM_OPEN_MODE.EDIT;

  const handleChangeForm = (key, value) => {
    setNotificationData({
      ...notificationData,
      [key]: value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    switch (mode) {
      case ENV_CONSTANTS.FORM_OPEN_MODE.CREATE:
        await createNotificationAction(notificationData);
        setLoading(false);
        handleClose(true);
        return true;

      case ENV_CONSTANTS.FORM_OPEN_MODE.EDIT:
        await editNotificationAction(notificationData);
        setLoading(false);
        handleClose(true);
        return true;

      default:
        return false;
    }
  };

  return (
    <>
      <h6 style={styles.titleText(appDataState.appData.theme)}>
        {`${mode} Notification`}
      </h6>

      <CustomInput
        required
        disabled={!canEdit}
        input={notificationData?.name}
        inputHandler={handleChangeForm}
        placeholder="Name"
        title="Name"
        name="name"
        rules="required"
        inputStyle={styles.inputStyle(appDataState.appData.theme)}
        isValid={(name, isValid) =>
          setFormValidation({ ...formValidation, [name]: isValid })
        }
      />
      <CustomInput
        required
        disabled={!canEdit}
        input={notificationData?.description}
        inputHandler={handleChangeForm}
        placeholder="Description"
        name="description"
        rules="required"
        inputAs="textarea"
        rows={5}
        inputStyle={styles.textAreaStyle(appDataState.appData.theme)}
        isValid={(name, isValid) =>
          setFormValidation({ ...formValidation, [name]: isValid })
        }
        title="Description"
      />

      <div className="mt-4">
        {canEdit && (
          <CustomButton
            type="secondary"
            title="Submit"
            handler={handleSubmit}
            disabled={!Object.values(formValidation).every(value => value)}
            isLoading={loading}
          />
        )}
      </div>
    </>
  );
};
