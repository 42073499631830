import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';
import { ENV_CONSTANTS } from '../Configs/Constants';
// All API request call apiHandler (helper function)

/**
 * Function to call invoices get api
 * @param {string} address
 * @returns invoices data
 */
export const getInvoicesData = (pagination = null) => {
  try {
    let url = ROUTE_NAMES.GET_INVOICES;
    if (pagination) {
      url += `?page=${pagination.currentPage}&limit=${pagination.dataLimit}`;
    }
    return apiHandler(REQUEST_METHODS.GET, url, {});
  } catch {
    return ENV_CONSTANTS.EMPTY_ARRAY;
  }
};

/**
 * Function to call mint invoice
 * @param {Object} reqBody
 * @returns Mint invoice data
 */
export const mintInvoice = reqBody => {
  try {
    return apiHandler(
      REQUEST_METHODS.POST,
      ROUTE_NAMES.MINT_INVOICES,
      {},
      {},
      reqBody,
    );
  } catch {
    return ENV_CONSTANTS.EMPTY_ARRAY;
  }
};
