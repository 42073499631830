import { toast } from 'react-toastify';
import {
  createNotification,
  editNotification,
} from '../../../../../../Apis/NotificationService';
import { MESSAGES } from '../../../../../../Configs/Messages';

export const editNotificationAction = async data => {
  const response = await editNotification(data);
  if (!response.error) {
    toast.success(MESSAGES.EDIT_NOTIFICATION_SUCCESS);
    return true;
  }
  toast.error(MESSAGES.SOME_ERROR);
  return false;
};

export const createNotificationAction = async data => {
  const response = await createNotification(data);
  if (!response.error) {
    toast.success(MESSAGES.CREATE_NOTIFICATION_SUCCESS);
    return true;
  }
  if (response.data.errors) {
    response.data.errors.forEach(error => {
      toast.error(error.msg);
    });
  }
  return false;
};
