export const NotificationBell = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.7868 19.32L24.4534 17.1066C24.1734 16.6133 23.9201 15.68 23.9201 15.1333V11.76C23.9201 7.4133 20.3868 3.86664 16.0268 3.86664C11.6668 3.86664 8.13342 7.4133 8.13342 11.76V15.1333C8.13342 15.68 7.88009 16.6133 7.60009 17.0933L6.25342 19.32C5.72009 20.2133 5.60009 21.2 5.93342 22.1066C6.25342 23 7.01342 23.6933 8.00009 24.0266C10.5868 24.9066 13.3068 25.3333 16.0268 25.3333C18.7468 25.3333 21.4668 24.9066 24.0534 24.04C24.9868 23.7333 25.7068 23.0266 26.0534 22.1066C26.4001 21.1866 26.3068 20.1733 25.7868 19.32Z"
      fill={fillColor}
    />
    <path
      d="M19.0002 4.42669C18.0802 4.06669 17.0802 3.86669 16.0269 3.86669C14.9869 3.86669 13.9869 4.05335 13.0669 4.42669C13.6402 3.34669 14.7736 2.66669 16.0269 2.66669C17.2936 2.66669 18.4136 3.34669 19.0002 4.42669Z"
      fill={fillColor}
    />
    <path
      d="M19.7736 26.68C19.2136 28.2267 17.7336 29.3334 16.0002 29.3334C14.9469 29.3334 13.9069 28.9067 13.1736 28.1467C12.7469 27.7467 12.4269 27.2134 12.2402 26.6667C12.4136 26.6934 12.5869 26.7067 12.7736 26.7334C13.0802 26.7734 13.4002 26.8134 13.7202 26.84C14.4802 26.9067 15.2536 26.9467 16.0269 26.9467C16.7869 26.9467 17.5469 26.9067 18.2936 26.84C18.5736 26.8134 18.8536 26.8 19.1202 26.76C19.3336 26.7334 19.5469 26.7067 19.7736 26.68Z"
      fill={fillColor}
    />
    <path
      d="M23 11.8C25.0987 11.8 26.8 10.0987 26.8 8C26.8 5.90132 25.0987 4.2 23 4.2C20.9013 4.2 19.2 5.90132 19.2 8C19.2 10.0987 20.9013 11.8 23 11.8Z"
      fill="#768CFA"
      stroke="white"
      strokeWidth="1.6"
    />
  </svg>
);
