import { useRef } from 'react';
import JoditEditor from 'jodit-react';

/**
 * Component for rich text editor.
 * @param {Function} onChange
 * @param {string} value
 */
export const RichTextEditor = ({ value, onChange }) => {
  const editor = useRef(null);
  return (
    <div>
      <JoditEditor
        ref={editor}
        value={value}
        onBlur={newContent => onChange(newContent)}
      />
    </div>
  );
};
