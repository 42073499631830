import { useContext } from 'react';
import { Container, Row, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDataContext } from '../../../Contexts/AppData';
import { toggleSidebar } from '../../../Actions/AppData';
import { APP_DATA } from '../../../Configs/AppData';
import { styles } from './Styles';
import { logout } from '../../../Actions/Auth';

/**
 * Component will be the main Navigation Bar for the application.
 *
 * @component
 */

export const Header = () => {
  const { state: appDataState } = useContext(AppDataContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <Container
        style={styles.headerMain(appDataState.appData.isDrawerOpen)}
        fluid="true"
      >
        <Row className="justify-content-between">
          <div className="d-flex me-2" style={styles.titleRow()}>
            <div>
              <Button
                className="d-sm-block d-md-none d-lg-none"
                style={styles.toggleButton(appDataState.appData.theme)}
                onClick={() => {
                  toggleSidebar(dispatch, !appDataState.appData.isDrawerOpen);
                }}
              >
                <i className="fa fa-bars fa-2x" aria-hidden="true" />
              </Button>
            </div>
            <h2 style={styles.headerTitle()}>{APP_DATA.HEADER_TITLE}</h2>
          </div>
          <Button
            style={styles.logoutButton(appDataState.appData.theme)}
            onClick={() => {
              navigate('/');
              logout(dispatch);
            }}
          >
            Logout
          </Button>
        </Row>
      </Container>
      <Container />
    </>
  );
};
