// Holds all NavLinks information and URLS

import { ICONS } from '../Components/Molecules/SvgElementWrapper/IconsConstants';

const URL = {
  INITIAL_PATH: '/',
  LOGIN: '/login',
  NOTIFICATION: '/notification',
  KYC: '/kyc',
  RELEASE: '/release',
  MINT_INVOICE: '/mint-invoice',
  DEFAULT: '*',
};

const NAV_LINKS = [
  {
    id: 1,
    path: URL.INITIAL_PATH,
    text: 'Home',
    icon: ICONS.HOME,
  },
  {
    id: 2,
    path: URL.NOTIFICATION,
    text: 'Notification',
    icon: ICONS.NOTIFICATION_BELL,
  },
  {
    id: 3,
    path: URL.KYC,
    text: 'Kyc',
    icon: ICONS.WALLET,
  },
  {
    id: 4,
    path: URL.RELEASE,
    text: 'Release',
    icon: ICONS.VIEW,
  },
  {
    id: 5,
    path: URL.MINT_INVOICE,
    text: 'Mint Invoice',
    icon: ICONS.MINT_INVOICE,
  },
];

export const URLS = { NAV_LINKS, URL };
