/**
 * Return styles for main Title
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainTitle(theme) {
  const style = {
    color: theme.primary.textColor,
    fontSize: 24,
    fontWeight: '600',
  };
  return style;
}

/**
 * Return styles for table Body
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function tableBody() {
  const style = {
    borderColor: 'transparent',
  };
  return style;
}

/**
 * Return styles for Table row
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function tableHead(theme) {
  const style = {
    padding: '1.5rem 2.5rem',
    fontWeight: '400',
    fontSize: 16,
    color: theme.primary.textColor,
    backgroundColor: theme.secondary.main,
  };
  return style;
}

/**
 * Return styles for Table row
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function middleElement(theme, isEven) {
  const style = {
    height: 52,
    padding: '0.5rem 2.5rem',
    alignItems: 'center',
    backgroundColor: isEven ? theme.primary.main : 'transparent',
    verticalAlign: 'middle',
    fontSize: 14,
    fontWeight: '400',
    color: theme.primary.textColor,
  };
  return style;
}
/**
 * Return styles for no data.
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function text() {
  const style = {
    fontSize: 20,
  };
  return style;
}
export const styles = {
  mainTitle,
  tableHead,
  middleElement,
  tableBody,
  text,
};
