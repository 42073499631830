/**
 * Return styles for text
 * @returns {CSSProperties}
 */
function text() {
  const style = {
    color: 'gray',
  };
  return style;
}
/**
 * Return styles for viewOnPolygon
 * @returns {CSSProperties}
 */
function viewOnPolygon() {
  const style = {
    backgroundColor: '#453f3f',
    borderRadius: 25,
    color: 'white',
    padding: 12,
    textDecoration: 'none',
  };
  return style;
}
export const styles = { viewOnPolygon, text };
