export const HomeIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.89568 22.5045V19.192C9.89568 18.3464 10.5862 17.6609 11.438 17.6609H14.5517C14.9607 17.6609 15.353 17.8222 15.6422 18.1094C15.9315 18.3965 16.094 18.7859 16.094 19.192V22.5045C16.0914 22.8561 16.2302 23.1941 16.4797 23.4436C16.7292 23.6931 17.0687 23.8334 17.4229 23.8334H19.5472C20.5393 23.836 21.4917 23.4465 22.1941 22.751C22.8966 22.0554 23.2913 21.111 23.2913 20.1261V10.6892C23.2913 9.89358 22.9361 9.13891 22.3213 8.62847L15.0949 2.89894C13.8378 1.89436 12.0367 1.92679 10.8172 2.97598L3.7556 8.62847C3.11181 9.12386 2.72702 9.88077 2.70801 10.6892V20.1164C2.70801 22.1693 4.38434 23.8334 6.4522 23.8334H8.52799C9.2635 23.8334 9.86125 23.2443 9.86658 22.5142L9.89568 22.5045Z"
      fill={fillColor}
    />
  </svg>
);
