import { Row, Col } from 'react-bootstrap';
import { ENV_CONSTANTS } from '../../../../../Configs/Constants';
import { styles } from './Styles';

/**
 * Component for the invoice card.
 *
 * @component
 */
export const InvoiceCard = ({ invoice }) => {
  const transaction = `${ENV_CONSTANTS.POLYGON_SCAN}${invoice?.transaction_id}`;
  return (
    <div className="p-5">
      <Row>
        <Col lg={6}>
          <div style={styles.text()}>NFT Id</div>
          <div>{invoice?.nft_id}</div>
        </Col>
        <Col lg={6}>
          <div style={styles.text()}>Tenure</div>
          <div>{invoice?.tenure}</div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={6}>
          <div style={styles.text()}>Buyer Location</div>
          <div>{invoice?.buyer_location}</div>
        </Col>
        <Col lg={6}>
          <div style={styles.text()}>Supplier Location</div>
          <div>{invoice?.supplier_location}</div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={6}>
          <div style={styles.text()}>Advance Ratio</div>
          <div>{invoice?.nft_id}</div>
        </Col>
        <Col lg={6}>
          <div style={styles.text()}>Pool Id</div>
          <div>{invoice?.pool_id}</div>
        </Col>
      </Row>
      <div className="mt-5">
        <div className="mx-auto" style={styles.text()}>
          Contract Id
        </div>
        <div>{invoice?.contract_id}</div>
      </div>
      <div className="mt-5">
        <a
          href={transaction}
          target="_blank"
          className="mx-auto"
          rel="noreferrer"
          style={styles.viewOnPolygon()}
        >
          View on PolygonScan
        </a>
      </div>
    </div>
  );
};
