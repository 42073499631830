import { Container, Row, Col } from 'react-bootstrap';
import { useContext } from 'react';
import { LoginForm } from '../../Molecules/LoginForm/LoginForm';
import { MESSAGES } from '../../../Configs/Messages';

import { styles } from './Styles';
import { AppDataContext } from '../../../Contexts/AppData';

/**
 * Component for Admin login page
 */
export const LoginPage = () => {
  const { state: appDataState } = useContext(AppDataContext);
  return (
    <Container style={styles.LoginContainer()} fluid>
      <Row style={styles.formCenterview()}>
        <Col md={5} />
        <Col md={7}>
          <h1 style={styles.titleText(appDataState.appData.theme)}>
            {MESSAGES.WELCOME_MESSAGE}
          </h1>
          <p style={styles.titleText(appDataState.appData.theme)}>
            {MESSAGES.SIGN_IN_MESSAGE}
          </p>
          <div>
            <LoginForm />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
