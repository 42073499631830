import { ENV_CONSTANTS } from '../Configs/Constants';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { uploadHandler } from '../Utils/uploadHandler';

/**
 * Upload a new file
 * @param {Object} file
 * @returns {JSON} file uri
 */
export const upload = async file => {
  try {
    const url = ROUTE_NAMES.FILE_UPLOAD;

    const result = await uploadHandler(url, file);

    return result.data;
  } catch {
    return ENV_CONSTANTS.EMPTY_ARRAY;
  }
};
