import { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { AppDataContext } from '../../../Contexts/AppData';
import { styles } from './Styles';

/**
 * Component to be the first component to show.
 *
 * @component
 */
export const CustomTable = ({ columns, data }) => {
  const { state: appDataState } = useContext(AppDataContext);
  return (
    <div>
      <Table responsive>
        <thead>
          <tr style={styles.tableHead(appDataState.appData.theme)}>
            {columns &&
              columns.map(column => {
                return (
                  <th key={column.id}>
                    <span>{column.name}</span>
                  </th>
                );
              })}
          </tr>
        </thead>

        <tbody style={styles.tableBody()}>
          {data &&
            data.map((rowData, index) => {
              const isEven = index % 2 === 0;
              return (
                <tr key={rowData._id}>
                  {columns &&
                    columns.map(column => {
                      const row = {
                        rowData,
                        rowIndex: index,
                      };
                      return (
                        <td
                          style={styles.middleElement(
                            appDataState.appData.theme,
                            isEven,
                          )}
                        >
                          {column.render
                            ? column.render(row)
                            : rowData[column.key]}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
        </tbody>
      </Table>
      {!data.length && (
        <div className="my-4" style={styles.text()}>
          No data available for this view
        </div>
      )}
    </div>
  );
};
