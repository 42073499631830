/**
 * Return styles for csvButton
 * @returns {CSSProperties}
 * csvButton sets styles for CSVLink Button
 *  * @param {Theme} theme .
 */
function csvButton(theme) {
  const style = {
    color: theme.common.white,
  };
  return style;
}

export const styles = {
  csvButton,
};
