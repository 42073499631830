import Select from 'react-select';
import makeAnimated from 'react-select/animated';

/**
 * Component to use as an Atom in the application.
 * This component create a Text Input with many select options.
 *
 * @component
 * @param {Function} inputHandler
 * @param {string} title
 * @param {object} options
 * @param {object} value
 */
export const MultipleSelect = ({
  inputHandler,
  title,
  options = [],
  value = null,
}) => {
  const handleInput = event => {
    inputHandler(event);
  };

  return (
    <div>
      <Select
        isMulti
        options={Object.values(options).map(data => ({
          label: data.name,
          value: data.value,
        }))}
        components={makeAnimated()}
        placeholder={title}
        onChange={handleInput}
        className="mt-4"
        classNamePrefix="select"
        defaultValue={
          value &&
          Object.values(value).map(data => ({
            label: data,
            value: data,
          }))
        }
      />
    </div>
  );
};
