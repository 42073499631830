/**
 * Return styles for custom Container for Login Page
 */
function LoginContainer() {
  const style = {
    background: '#212121',
    minHeight: '100vh',
  };
  return style;
}

/**
 * Return styles for form Centerview
 */
function formCenterview() {
  const style = {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
  };
  return style;
}

/**
 * Styles for title text
 */
function titleText(theme) {
  const style = {
    color: theme.common.white,
  };
  return style;
}

export const styles = {
  LoginContainer,
  formCenterview,
  titleText,
};
