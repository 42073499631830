import axios from 'axios';
import { ENV_CONSTANTS } from '../Configs/Constants';
import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { RESPONSE_CODES } from '../Configs/ResponseCodes';
import { HELPERS } from '../Helpers';

/**
 * Handler for upload files.
 * @param {*} url
 * @param {*} file
 * @returns
 */

export const uploadHandler = async (url, file = {}) => {
  try {
    const baseURL = `${ENV_CONSTANTS.SERVER_URL}${url}`;
    const store = JSON.parse(localStorage.getItem(ENV_CONSTANTS.STORAGE_NAME));
    const token = store?.auth?.accessToken;

    const Headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    };

    const data = {
      method: REQUEST_METHODS.POST,
      url: baseURL,
      headers: {
        ...Headers,
      },
      data: file,
    };
    return axios(data)
      .then(response => response.data)
      .then(response => {
        switch (response.code) {
          case RESPONSE_CODES.OK:
            return HELPERS.responseHandler(response);
          case RESPONSE_CODES.UNPROCESSABLE_ENTITY:
            return false;
          default:
            return HELPERS.responseHandler(response);
        }
      })
      .catch(error => error);
  } catch (error) {
    return error;
  }
};
