import { ACTION_TYPES } from '../../Configs/ActionTypes';

/**
 * Initial state will be dispatched.
 * @constant {loginInitialState} loginInitialState .
 */
const loginInitialState = {
  accessToken: '',
};

/**
 * Returns a state for Login
 * @returns {loginInitialState}
 * The reducers receive all User Login actions and return the appropriate state.
 * @param {loginInitialState} state ..
 */
const authReducer = (state = loginInitialState, action = () => {}) => {
  const loginState = state;
  switch (action.type) {
    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state.loginState,
        accessToken: action.payload.access_token,
      };
    case ACTION_TYPES.CLEAR_USER_DATA:
      return {
        ...state.loginState,
        accessToken: '',
      };
    default:
      return loginState;
  }
};

export default authReducer;
