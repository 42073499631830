/**
 * Define all Action Types we need to dispatch in the application.
 */
const GET_APP_DATA = 'GET_APP_DATA';

/**
 * Define all Login Action Types
 *
 */
const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const LOGIN_UNAUTHORIZED = 'LOGIN_UNAUTHORIZED';
const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
const SET_THEME = 'SET_THEME';

/**
 * Export all Action Types that the application will use and dispatch it.
 * @constant {Object} ACTION_TYPES .
 */
export const ACTION_TYPES = {
  GET_APP_DATA,
  SET_THEME,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  CLEAR_USER_DATA,
  LOGIN_FAILURE,
  LOGIN_UNAUTHORIZED,
  SET_NOTIFICATIONS,
};
