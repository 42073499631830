/* eslint-disable no-restricted-globals */
import { ENV_CONSTANTS } from '../Configs/Constants';
import { RESPONSE_CODES } from '../Configs/ResponseCodes';
import { HELPERS } from '../Helpers';

/**
 *
 * apiHandler: Is a helper function used to perform all API calls to the server and centralize success and error handling
 * This helper function will use the base URL of the server
 * Create an API instance to perform all types of requests
 * It takes 5 parameters:
 * method: Switched parameter, for define the request method type
 * url: Any additional url appending to the base URL
 * reqBody: Data will be sent to the server
 * reqHeaders: Any additional headers to be sent to the server
 * params: Any additional parameters to be sent to the server
 */
export const apiHandler = async (
  method,
  url,
  headers = {},
  params = {},
  reqBody = null,
  baseURL = ENV_CONSTANTS.SERVER_URL,
) => {
  try {
    const store = JSON.parse(localStorage.getItem(ENV_CONSTANTS.STORAGE_NAME));

    const token = store?.auth?.accessToken;
    const Headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    let data = {
      method,
      headers: {
        ...Headers,
        ...headers,
      },
    };

    if (reqBody) {
      data = { ...data, body: JSON.stringify(reqBody) };
    }

    let uri = `${baseURL}${url}`;
    const paramKeys = Object.keys(params);
    if (paramKeys.length) {
      uri += '?';
      paramKeys.forEach(key => {
        uri += `${key}=${params[key]}&`;
      });
    }

    return fetch(uri, data)
      .then(response => response.json())
      .then(response => {
        switch (response.code) {
          case RESPONSE_CODES.OK:
            return HELPERS.responseHandler(response);
          case RESPONSE_CODES.UNAUTHORIZED:
            localStorage.clear();
            history.go('/');
            return false;

          default:
            return HELPERS.responseHandler(response);
        }
      })
      .catch(error => {
        return { isError: true, error };
      });
  } catch (error) {
    //will be logged later
  }
  return reqBody;
};
